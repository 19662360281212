import * as React from "react";
import PropTypes from 'prop-types';

function SvgGrid(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M7.104 8.938h-2.52A1.833 1.833 0 012.75 7.104v-2.52c0-1.014.82-1.834 1.833-1.834h2.521c1.013 0 1.833.82 1.833 1.833v2.521c0 1.013-.82 1.833-1.833 1.833zM17.417 8.938h-2.521a1.833 1.833 0 01-1.834-1.834v-2.52c0-1.014.82-1.834 1.834-1.834h2.52c1.014 0 1.834.82 1.834 1.833v2.521c0 1.013-.82 1.833-1.833 1.833zM7.104 19.25h-2.52a1.833 1.833 0 01-1.834-1.833v-2.521c0-1.013.82-1.834 1.833-1.834h2.521c1.013 0 1.833.82 1.833 1.834v2.52c0 1.014-.82 1.834-1.833 1.834zM17.417 19.25h-2.521a1.833 1.833 0 01-1.834-1.833v-2.521c0-1.013.82-1.834 1.834-1.834h2.52c1.014 0 1.834.82 1.834 1.834v2.52c0 1.014-.82 1.834-1.833 1.834z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgGrid.propTypes = { currentColor: PropTypes.string };SvgGrid.defaultProps = { stroke: '#3E21DE' };export default SvgGrid;
