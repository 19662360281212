import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgRemove = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path d="M38 26H10v-4h28v4z" fill={props.currentColor} />
	</svg>
);

SvgRemove.propTypes = {
	currentColor: PropTypes.string
};

SvgRemove.defaultProps = {
	fill: colors.black
};

export default SvgRemove;
