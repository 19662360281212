import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgLearn = props => (
	<svg width="1em" height="1em" viewBox="0 0 44 36" fill="none" {...props}>
		<path
			d="M8 20.36V28.36L22 36L36 28.36V20.36L22 28L8 20.36ZM22 0L0 12L22 24L40 14.18V28H44V12L22 0Z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgLearn.propTypes = {
	currentColor: PropTypes.string
};

SvgLearn.defaultProps = {
	fill: colors.black
};

export default SvgLearn;
