import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { radius, brandColors, shadows } from "../guidelines/shared";
import Button from "../elements/Button";

const StyledCard = styled.div`
	width: 100%;
	height: auto;
	box-shadow: ${shadows.cardShadow};
	border-radius: ${radius.semi_hard};
	transform: scale(1);
	transition: all 0.4s;

	&:hover {
		transform: scale(1.03);
		transition: all 0.4s;
	}
`;

const Card = props => {
	return <StyledCard {...props}></StyledCard>;
};

// StyledCard.PropTypes = {};

// StyledCard.defaultProps = {};

export default Card;
