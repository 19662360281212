import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { radius, colors } from '../guidelines/shared';

const StyledInputBox = styled.div`
    potision: relative;
    background: ${props => props.bg};
    border: 0;
    color: ${props => props.color};
    border-radius: ${props => props.radius}
    height: auto;
    width: 100%;
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    line-height: 1;
    outline: 0;
    margin-bottom: 10px;  

  label {
    font-family: inherit
    font-style: normal;
    font-weight: 500;
    font-size: ${props => props.size};
    padding: 10px 20px 10px 0;
    display: flex;
    align-items: center;
    letter-spacing: 0.2rem;
    color: #9E9E9E;
  }

  input {
    height: 40px;
    border: 2px solid #E2E0EC;
    border-radius: 4px;
    font-family: inherit
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #151515;
    width: 100%;

    &:focus {
      outline: 0;
      border: 2px solid ${colors.brandBlue};
      background: #F1EFFD;
    }
  }
`;

const Div = styled.div`
  position: relative;
`;

// top: 10px;
const Span = styled.span`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -40%);
`;

const LeftSpan = styled.span`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translate(0, -50%);
`;

const Input = styled.input`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: block;
  width: 100%;
  padding: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 14px;
  line-height: inherit;
  border: 1px solid;
  border-radius: default;
  color: inherit;
  background-color: transparent;

  &::placeholder {
    color: #c9c9c9;
  }
`;

const InputBox = props => {
  const { label, id, icon, leftIcon, type, validate, disabled } = props;
  return (
    <div>
      <StyledInputBox mr={2} {...props}>
        <label htmlFor={id}>{label}</label>
        <Div>
          {leftIcon && <LeftSpan>{leftIcon}</LeftSpan>}
          <Input id={id} name={name} type={type} ref={validate} disabled={disabled} {...props} />
          {icon && <Span>{icon}</Span>}
        </Div>
      </StyledInputBox>
    </div>
  );
};

StyledInputBox.propTypes = {
  size: PropTypes.string,
  radius: PropTypes.string
};

StyledInputBox.defaultProps = {
  size: '12px',
  radius: radius.hard
};

export default InputBox;
