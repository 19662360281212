import * as React from "react";
import PropTypes from 'prop-types';

function SvgUsability(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 12" fill="none" {...props}>
      <path
        d="M14 9.333H2v-8h12v8zM14 0H2C1.266 0 .667.6.667 1.333v8A1.333 1.333 0 002 10.667h3.333V12h5.333v-1.333H14c.74 0 1.333-.594 1.333-1.334v-8A1.333 1.333 0 0014 0zm-4 2.333l-.414.914-.92.42.92.42L10 5l.42-.913.913-.42-.913-.42-.42-.914zM7 3.667l-.727 1.606L4.667 6l1.606.727L7 8.333l.733-1.606L9.333 6l-1.6-.727L7 3.667z"
        fill={props.currentColor}
      />
    </svg>
  );
}

SvgUsability.propTypes = { currentColor: PropTypes.string };SvgUsability.defaultProps = { fill: '#3E21DE' };export default SvgUsability;
