import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgArrowDropUp = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path d="M14 28l10-10 10 10H14z" fill={props.currentColor} />
	</svg>
);

SvgArrowDropUp.propTypes = {
	currentColor: PropTypes.string
};

SvgArrowDropUp.defaultProps = {
	fill: colors.black
};

export default SvgArrowDropUp;
