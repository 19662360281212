import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgArrowDropDown = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path d="M14 20l10 10 10-10H14z" fill={props.currentColor} />
	</svg>
);

SvgArrowDropDown.propTypes = {
	currentColor: PropTypes.string
};

SvgArrowDropDown.defaultProps = {
	fill: colors.black
};

export default SvgArrowDropDown;
