import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgSave = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M34 6H10c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4V14l-8-8zM24 38c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zm6-20H10v-8h20v8z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgSave.propTypes = {
	currentColor: PropTypes.string
};

SvgSave.defaultProps = {
	fill: colors.black
};

export default SvgSave;
