import * as React from "react";
import PropTypes from 'prop-types';

function SvgHierarchy(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M12.667 3.667L1.833 9.517l7.5 4.233L20.168 7.9l-7.5-4.234z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M12.667 8.25L1.833 14.1l7.5 4.233 10.834-5.85-7.5-4.233z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgHierarchy.propTypes = { currentColor: PropTypes.string };SvgHierarchy.defaultProps = { stroke: '#3E21DE' };export default SvgHierarchy;
