import * as React from "react";
import PropTypes from 'prop-types';

function SvgAbTesting(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M15.028 16.042h-2.286v-2.03h2.284c.561 0 1.016.454 1.016 1.015v0c0 .56-.454 1.015-1.014 1.015z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.742 14.011v-2.095h2.028c.579 0 1.048.47 1.048 1.048v0c0 .579-.47 1.048-1.048 1.048M10.084 10.084L8.02 5.959l-2.063 4.125M6.322 9.356H9.72"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M16.645 19.25H5.355a2.605 2.605 0 01-2.605-2.605V5.355A2.605 2.605 0 015.355 2.75h11.29a2.605 2.605 0 012.605 2.605v11.29a2.605 2.605 0 01-2.605 2.605z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgAbTesting.propTypes = { currentColor: PropTypes.string };SvgAbTesting.defaultProps = { stroke: '#3E21DE' };export default SvgAbTesting;
