import * as React from "react";
import PropTypes from 'prop-types';

function SvgAddAoi(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 24" fill="none" {...props}>
      <path
        d="M16.333 4.667v5.5M19.083 7.417h-5.5"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M14.347 16.592H10.52l-2.451 2.43c-.674.668-1.819.19-1.819-.758v-9.77c0-.95 1.15-1.427 1.822-.754l7.03 7.03c.672.672.196 1.822-.755 1.822z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgAddAoi.propTypes = { currentColor: PropTypes.string };SvgAddAoi.defaultProps = { stroke: '#3E21DE' };export default SvgAddAoi;
