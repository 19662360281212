import * as React from "react";
import PropTypes from 'prop-types';

function SvgHorizontal(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M6 10a2 2 0 10-.001 3.999A2 2 0 006 10zm12 0a2 2 0 10-.001 3.999A2 2 0 0018 10zm-6 0a2 2 0 10-.001 3.999A2 2 0 0012 10z"
        fill={props.currentColor}
      />
    </svg>
  );
}

SvgHorizontal.propTypes = { currentColor: PropTypes.string };SvgHorizontal.defaultProps = { fill: '#212121' };export default SvgHorizontal;
