import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgCardMenuVertical = props => (
	<svg width="1em" height="1em" viewBox="0 0 8 32" fill="none" {...props}>
		<path
			d="M4 8c2.21 0 4-1.79 4-4S6.21 0 4 0 0 1.79 0 4s1.79 4 4 4zm0 4c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 12c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgCardMenuVertical.propTypes = {
	currentColor: PropTypes.string
};

SvgCardMenuVertical.defaultProps = {
	fill: colors.black
};

export default SvgCardMenuVertical;
