import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgChevronRight = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path d="M20 12l-2.83 2.83L26.34 24l-9.17 9.17L20 36l12-12-12-12z" fill={props.currentColor} />
	</svg>
);

SvgChevronRight.propTypes = {
	currentColor: PropTypes.string
};

SvgChevronRight.defaultProps = {
	fill: colors.black
};

export default SvgChevronRight;
