import * as React from "react";
import PropTypes from 'prop-types';

function SvgLock(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M11.054 13.5H4.943a1.222 1.222 0 01-1.222-1.222v-4.89c0-.674.547-1.221 1.222-1.221h6.111c.675 0 1.222.547 1.222 1.222v4.889c0 .675-.547 1.222-1.222 1.222z"
        stroke={props.currentColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.999 11.11V9.528M8.323 8.745a.458.458 0 11-.648.649.458.458 0 01.648-.649M5.555 6.167V4.944v0A2.445 2.445 0 017.999 2.5v0a2.445 2.445 0 012.445 2.444v1.223"
        stroke={props.currentColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgLock.propTypes = { currentColor: PropTypes.string };SvgLock.defaultProps = { stroke: '#E0E0E0' };export default SvgLock;
