import * as React from "react";
import PropTypes from 'prop-types';

function SvgVertical(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 8a2 2 0 10.001-3.999A2 2 0 0012 8zm0 2a2 2 0 10-.001 3.999A2 2 0 0012 10zm0 6a2 2 0 10-.001 3.999A2 2 0 0012 16z"
        fill={props.currentColor}
      />
    </svg>
  );
}

SvgVertical.propTypes = { currentColor: PropTypes.string };SvgVertical.defaultProps = { fill: '#212121' };export default SvgVertical;
