import * as React from "react";
import PropTypes from 'prop-types';

function SvgBigKey(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M43.42 13.06l-2.84-2.82 2.84-2.82a2.008 2.008 0 10-2.84-2.84L19.5 25.66a10 10 0 102.84 2.84l9.76-9.78 2.82 2.84a2 2 0 001.42.58 1.999 1.999 0 001.42-3.42L34.92 16l2.84-2.84L40.58 16a2 2 0 001.42.58 1.999 1.999 0 001.42-.58 2.002 2.002 0 000-2.94zM14 40a6 6 0 110-12 6 6 0 010 12z"
        fill={props.currentColor}
      />
    </svg>
  );
}

SvgBigKey.propTypes = { currentColor: PropTypes.string };SvgBigKey.defaultProps = { fill: '#000' };export default SvgBigKey;
