import * as React from "react";
import PropTypes from 'prop-types';

function SvgDelete(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M8.333 8.333l7.334 7.334M15.667 8.333l-7.334 7.334"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgDelete.propTypes = { currentColor: PropTypes.string };SvgDelete.defaultProps = { stroke: '#212121' };export default SvgDelete;
