import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgAdd = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path d="M38 26H26v12h-4V26H10v-4h12V10h4v12h12v4z" fill={props.currentColor} />
	</svg>
);

SvgAdd.propTypes = {
	currentColor: PropTypes.string
};

SvgAdd.defaultProps = {
	fill: colors.black
};

export default SvgAdd;
