import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgFullscreen = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M14 28h-4v10h10v-4h-6v-6zm-4-8h4v-6h6v-4H10v10zm24 14h-6v4h10V28h-4v6zm-6-24v4h6v6h4V10H28z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgFullscreen.propTypes = {
	currentColor: PropTypes.string
};

SvgFullscreen.defaultProps = {
	fill: colors.black
};

export default SvgFullscreen;
