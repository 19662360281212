import React from 'react';
import styled from 'styled-components';
import { fonts } from '../guidelines/shared';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
	@import-normalize;
	font-family: 'Roboto', sans-serif;
  }
`;

const GlobalFonts = styled.div`
	padding: 0 50px;
`;

const Global = props => {
	return (
		<div>
			<GlobalStyle />
			<GlobalFonts>{props.children}</GlobalFonts>
		</div>
	);
};

export default Global;
