import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgSettings = props => (
	<svg width="1em" height="1em" viewBox="0 0 39 40" fill="none" {...props}>
		<path
			d="M34.314 21.95c.08-.64.14-1.29.14-1.95 0-.66-.06-1.31-.14-1.95l4.23-3.31c.38-.3.49-.84.24-1.28l-4-6.93c-.25-.43-.77-.61-1.22-.43l-4.98 2.01c-1.03-.79-2.16-1.46-3.38-1.97l-.75-5.3c-.09-.47-.5-.84-1-.84h-8c-.5 0-.91.37-.99.84l-.75 5.3a14.8 14.8 0 00-3.38 1.97L5.354 6.1a1 1 0 00-1.22.43l-4 6.93c-.25.43-.14.97.24 1.28l4.22 3.31a15.7 15.7 0 00-.14 1.95c0 .66.06 1.31.14 1.95l-4.22 3.31c-.38.3-.49.84-.24 1.28l4 6.93c.25.43.77.61 1.22.43l4.98-2.01c1.03.79 2.16 1.46 3.38 1.97l.75 5.3c.08.47.49.84.99.84h8c.5 0 .91-.37.99-.84l.75-5.3a14.8 14.8 0 003.38-1.97l4.98 2.01a1 1 0 001.22-.43l4-6.93c.25-.43.14-.97-.24-1.28l-4.22-3.31zM19.454 27c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgSettings.propTypes = {
	currentColor: PropTypes.string
};

SvgSettings.defaultProps = {
	fill: colors.black
};

export default SvgSettings;
