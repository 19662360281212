import * as React from "react";
import PropTypes from 'prop-types';

function SvgChevronDown(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M8.333 10.167L12 13.833l3.667-3.666"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgChevronDown.propTypes = { currentColor: PropTypes.string };SvgChevronDown.defaultProps = { stroke: '#212121' };export default SvgChevronDown;
