import * as React from "react";
import PropTypes from 'prop-types';

function SvgMagGlass(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 31" fill="none" {...props}>
      <path
        d="M19.224 12.857l-4.178 4.18-2.787-2.786-2.785 2.786"
        stroke={props.currentColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.802 8.493A9.126 9.126 0 117.896 21.4 9.126 9.126 0 0120.802 8.493M24.778 25.377l-3.972-3.973"
        stroke={props.currentColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgMagGlass.propTypes = { currentColor: PropTypes.string };SvgMagGlass.defaultProps = { stroke: '#3E21DE' };export default SvgMagGlass;
