import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgUnlocked = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 42" fill="none" {...props}>
		<path
			d="M16 32c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm12-18h-2v-4c0-5.52-4.48-10-10-10S6 4.48 6 10h3.8c0-3.42 2.78-6.2 6.2-6.2 3.42 0 6.2 2.78 6.2 6.2v4H4c-2.21 0-4 1.79-4 4v20c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4V18c0-2.21-1.79-4-4-4zm0 24H4V18h24v20z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgUnlocked.propTypes = {
	currentColor: PropTypes.string
};

SvgUnlocked.defaultProps = {
	fill: colors.black
};

export default SvgUnlocked;
