import * as React from "react";
import PropTypes from 'prop-types';

function SvgDoubleLeft(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M9.625 7.333l-3.667 3.666 3.667 3.667M16.042 6.416L11.459 11l4.583 4.583"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgDoubleLeft.propTypes = { currentColor: PropTypes.string };SvgDoubleLeft.defaultProps = { stroke: '#3E21DE' };export default SvgDoubleLeft;
