import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { radius, colors } from '../guidelines/shared';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  paper: {
    padding: theme.spacing(1, 2)
  }
}));

const StyledBreadcrumbs = withStyles(theme => ({
  root: {
    backgroundColor: '#fff'
  },
  li: {
    color: '#C9C9C9',
    '& p': { color: colors.primary }
  }
}))(Breadcrumbs);

const SimpleBreadcrumbs = props => {
  const classes = useStyles();
  const { breadcrumbs } = props;

  let lastBreadcrumb = breadcrumbs.slice(-1)[0];
  let allBreadcrumbs = breadcrumbs.slice(0, -1);

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <StyledBreadcrumbs aria-label="breadcrumb">
          {allBreadcrumbs.map((b, i) => {
            return (
              <Link color="inherit" href={b.link} key="i">
                {b.text}
              </Link>
            );
          })}
          <Typography color="textPrimary">{lastBreadcrumb.text}</Typography>
        </StyledBreadcrumbs>
      </Paper>
    </div>
  );
};

export default SimpleBreadcrumbs;
