import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const LearnNew = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M42.98 20.3802L40.98 19.2802L22.98 9.28016H22.76C22.6374 9.22831 22.5102 9.18814 22.38 9.16016H22H21.64C21.5034 9.18815 21.3695 9.22831 21.24 9.28016H21.02L3.02 19.2802C2.7122 19.4546 2.45619 19.7076 2.27807 20.0132C2.09995 20.3189 2.0061 20.6664 2.0061 21.0202C2.0061 21.3739 2.09995 21.7214 2.27807 22.0271C2.45619 22.3328 2.7122 22.5857 3.02 22.7602L8 25.5202V35.0002C8 36.5915 8.63214 38.1176 9.75736 39.2428C10.8826 40.368 12.4087 41.0002 14 41.0002H30C31.5913 41.0002 33.1174 40.368 34.2426 39.2428C35.3679 38.1176 36 36.5915 36 35.0002V25.5202L40 23.2802V29.0002C40 29.5306 40.2107 30.0393 40.5858 30.4144C40.9609 30.7894 41.4696 31.0002 42 31.0002C42.5304 31.0002 43.0391 30.7894 43.4142 30.4144C43.7893 30.0393 44 29.5306 44 29.0002V22.1202C43.9994 21.7658 43.9046 21.4179 43.7254 21.1122C43.5462 20.8064 43.2889 20.5538 42.98 20.3802ZM32 35.0002C32 35.5306 31.7893 36.0393 31.4142 36.4144C31.0391 36.7894 30.5304 37.0002 30 37.0002H14C13.4696 37.0002 12.9609 36.7894 12.5858 36.4144C12.2107 36.0393 12 35.5306 12 35.0002V27.7402L21.02 32.7402L21.32 32.8602H21.5C21.666 32.8811 21.834 32.8811 22 32.8602C22.166 32.8811 22.334 32.8811 22.5 32.8602H22.68C22.7862 32.8378 22.8877 32.7972 22.98 32.7402L32 27.7402V35.0002ZM22 28.7202L8.12 21.0002L22 13.2802L35.88 21.0002L22 28.7202Z"
			fill={props.currentColor}
		/>
	</svg>
);

LearnNew.propTypes = {
	currentColor: PropTypes.string
};

LearnNew.defaultProps = {
	fill: colors.black
};

export default LearnNew;
