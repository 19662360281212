import * as React from "react";
import PropTypes from 'prop-types';

function SvgTiltAttachment(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M11.327 8.475l1.426-1.426a2.69 2.69 0 000-3.803v0a2.69 2.69 0 00-3.803 0L7.524 4.672M6.1 9.9l3.8-3.8M4.672 7.523L3.246 8.95a2.69 2.69 0 000 3.803v0a2.69 2.69 0 003.803 0l1.426-1.426"
        stroke={props.currentColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgTiltAttachment.propTypes = { currentColor: PropTypes.string };SvgTiltAttachment.defaultProps = { stroke: '#212121' };export default SvgTiltAttachment;
