import * as React from "react";
import PropTypes from 'prop-types';

function SvgPreview(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 31 31" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M18.556 19.585h-6.111a1.528 1.528 0 01-1.528-1.528v-6.111c0-.844.684-1.528 1.528-1.528h6.11c.844 0 1.529.684 1.529 1.528v6.11c0 .844-.685 1.529-1.528 1.529z"
        stroke={props.currentColor}
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.14 1.251h6.112v6.111M29.249 1.251l-5.5 5.5M7.862 28.752H1.75V22.64M1.75 28.75l5.5-5.5M1.75 7.362V1.251h6.112M1.75 1.251l5.5 5.5M29.252 22.64v6.112H23.14M29.249 28.75l-5.5-5.5"
        stroke={props.currentColor}
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgPreview.propTypes = { currentColor: PropTypes.string };SvgPreview.defaultProps = { stroke: '#F5F5F5' };export default SvgPreview;
