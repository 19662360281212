import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgInbox = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M38 6H9.98c-2.21 0-3.96 1.79-3.96 4L6 38c0 2.21 1.77 4 3.98 4H38c2.21 0 4-1.79 4-4V10c0-2.21-1.79-4-4-4zm0 24h-8c0 3.31-2.69 6-6 6s-6-2.69-6-6H9.98V10H38v20z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgInbox.propTypes = {
	currentColor: PropTypes.string
};

SvgInbox.defaultProps = {
	fill: colors.black
};

export default SvgInbox;
