import * as React from "react";
import PropTypes from 'prop-types';

function SvgPlay(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M11.03 9.303l3.558 2.105c.45.266.45.917 0 1.183l-3.558 2.105a.688.688 0 01-1.038-.592V9.895c0-.532.58-.863 1.038-.592v0z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.277 8.055H6.93 4.277V5.403M3.75 12a8.25 8.25 0 101.004-3.945"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgPlay.propTypes = { currentColor: PropTypes.string };SvgPlay.defaultProps = { stroke: '#C3BAF5' };export default SvgPlay;
