import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { radius, brandColors, shadows } from '../guidelines/shared';
import Button from '../elements/Button';

const StyledCard = styled.div`
	background-image: url(${props => props.cardImage});
	background-position: center;
	background-size: cover;
	min-height: 272px;
	padding: 32px;
	border-top-left-radius: ${radius.semi_hard};
	border-top-right-radius: ${radius.semi_hard};
	display: flex;
	align-items: start;

	&& button {
		font-weight: 500;
	}
`;

const Box = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&& span {
		background: #fff;
		border-radius: 50px;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&& button {
		padding: 0.6rem 2rem;
		max-height: 40px;
	}
`;

const UpperCard = props => {
	return (
		<StyledCard {...props}>
			<Box>
				<span>
					<img src={props.url} alt="icon" />
				</span>
				{props.children}
			</Box>
		</StyledCard>
	);
};

// StyledCard.PropTypes = {};

// StyledCard.defaultProps = {};

export default UpperCard;
