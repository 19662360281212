import * as React from "react";
import PropTypes from 'prop-types';

function SvgDesktop(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M18.996 16.43H5.004a1.833 1.833 0 01-1.833-1.833V5.64c0-1.013.82-1.833 1.833-1.833h13.991c1.013 0 1.833.82 1.833 1.833v8.958c.001 1.012-.82 1.832-1.832 1.832z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.57 20.25c3.55-1.222 9.31-1.222 12.861 0"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgDesktop.propTypes = { currentColor: PropTypes.string };SvgDesktop.defaultProps = { stroke: '#212121' };export default SvgDesktop;
