import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgVisible = props => (
	<svg width="1em" height="1em" viewBox="0 0 44 30" fill="none" {...props}>
		<path
			d="M22 0C12 0 3.46 6.22 0 15c3.46 8.78 12 15 22 15 10.01 0 18.54-6.22 22-15C40.54 6.22 32.01 0 22 0zm0 25c-5.52 0-10-4.48-10-10S16.48 5 22 5s10 4.48 10 10-4.48 10-10 10zm0-16c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgVisible.propTypes = {
	currentColor: PropTypes.string
};

SvgVisible.defaultProps = {
	fill: colors.black
};

export default SvgVisible;
