import * as React from "react";
import PropTypes from 'prop-types';

function SvgSignOut(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M25.18 26l-4.6 4.58a2 2 0 000 2.84 2 2 0 002.84 0l8-8a2 2 0 00.42-.66 2 2 0 000-1.52 2 2 0 00-.42-.66l-8-8a2.008 2.008 0 00-2.84 2.84l4.6 4.58H6a2 2 0 100 4h19.18zM24 4A20 20 0 006 15.1a2.012 2.012 0 003.6 1.8A16 16 0 1124 40a15.86 15.86 0 01-14.32-8.9 2.013 2.013 0 00-3.6 1.8A20 20 0 1024 4z"
        fill={props.currentColor}
      />
    </svg>
  );
}

SvgSignOut.propTypes = { currentColor: PropTypes.string };SvgSignOut.defaultProps = { fill: '#000' };export default SvgSignOut;
