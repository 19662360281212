import * as React from "react";
import PropTypes from 'prop-types';

function SvgClarity(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M16.86 4.064A.2.2 0 0016.712 4h-9.36a.2.2 0 00-.145.062L2.527 8.958a.2.2 0 00-.006.27l9.33 10.602a.2.2 0 00.3 0L21.48 9.287a.2.2 0 00-.004-.269L16.86 4.064zM7.337 9.818a.2.2 0 01.182.117l1.964 4.285c.096.21-.18.388-.332.215l-3.77-4.285a.2.2 0 01.15-.332h1.806zm7.15 0a.2.2 0 01.182.284l-2.488 5.429a.2.2 0 01-.364 0L9.33 10.1a.2.2 0 01.182-.283h4.976zm-4.325-1.454a.2.2 0 01-.148-.336l1.838-2.004a.2.2 0 01.294 0l1.838 2.004a.2.2 0 01-.148.336h-3.674zm6.317 1.57a.2.2 0 01.182-.116h1.85a.2.2 0 01.15.333l-3.839 4.338c-.152.172-.428-.007-.332-.216l1.99-4.338zm1.975-1.907a.2.2 0 01-.146.337h-1.846a.2.2 0 01-.147-.065l-2.3-2.51a.2.2 0 01.147-.334h1.808a.2.2 0 01.147.063l2.337 2.51zM7.938 5.517a.2.2 0 01.144-.062h1.755a.2.2 0 01.148.335l-2.3 2.509a.2.2 0 01-.148.065H5.684a.2.2 0 01-.145-.339l2.399-2.509z"
        fill={props.currentColor}
      />
    </svg>
  );
}

SvgClarity.propTypes = { currentColor: PropTypes.string };SvgClarity.defaultProps = { fill: '#212121' };export default SvgClarity;
