import * as React from "react";
import PropTypes from 'prop-types';

function SvgChevronRight(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M10.167 15.667L13.833 12l-3.666-3.667"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgChevronRight.propTypes = { currentColor: PropTypes.string };SvgChevronRight.defaultProps = { stroke: '#212121' };export default SvgChevronRight;
