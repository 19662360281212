import * as React from "react";
import PropTypes from 'prop-types';

function SvgAoi(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M7.444 9.889V22.11M24.555 22.111V9.89"
        stroke={props.currentColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M25.777 27h-2.444a1.223 1.223 0 01-1.223-1.222v-2.445c0-.674.548-1.222 1.223-1.222h2.444c.675 0 1.222.548 1.222 1.222v2.445c0 .674-.547 1.222-1.222 1.222zM8.667 27H6.222A1.223 1.223 0 015 25.778v-2.445c0-.674.548-1.222 1.222-1.222h2.445c.674 0 1.222.548 1.222 1.222v2.445c0 .674-.548 1.222-1.222 1.222zM25.777 9.889h-2.444a1.223 1.223 0 01-1.223-1.222V6.222c0-.674.548-1.222 1.223-1.222h2.444c.675 0 1.222.548 1.222 1.222v2.445c0 .674-.547 1.222-1.222 1.222zM8.667 9.889H6.222A1.223 1.223 0 015 8.667V6.222C5 5.548 5.548 5 6.222 5h2.445c.674 0 1.222.548 1.222 1.222v2.445c0 .674-.548 1.222-1.222 1.222z"
        stroke={props.currentColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.89 24.555h12.222M22.112 7.444H9.89"
        stroke={props.currentColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgAoi.propTypes = { currentColor: PropTypes.string };SvgAoi.defaultProps = { stroke: '#3E21DE' };export default SvgAoi;
