import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgTimeline = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M46 16c0 2.2-1.8 4-4 4-.36 0-.7-.04-1.02-.14l-7.12 7.1c.1.32.14.68.14 1.04 0 2.2-1.8 4-4 4s-4-1.8-4-4c0-.36.04-.72.14-1.04l-5.1-5.1c-.32.1-.68.14-1.04.14s-.72-.04-1.04-.14l-9.1 9.12c.1.32.14.66.14 1.02 0 2.2-1.8 4-4 4s-4-1.8-4-4 1.8-4 4-4c.36 0 .7.04 1.02.14l9.12-9.1c-.1-.32-.14-.68-.14-1.04 0-2.2 1.8-4 4-4s4 1.8 4 4c0 .36-.04.72-.14 1.04l5.1 5.1c.32-.1.68-.14 1.04-.14s.72.04 1.04.14l7.1-7.12A3.4 3.4 0 0138 16c0-2.2 1.8-4 4-4s4 1.8 4 4z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgTimeline.propTypes = {
	currentColor: PropTypes.string
};

SvgTimeline.defaultProps = {
	fill: colors.black
};

export default SvgTimeline;
