import * as React from "react";
import PropTypes from 'prop-types';

function SvgAddNew(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M17.5 20.25h-11a2.75 2.75 0 01-2.75-2.75v-11A2.75 2.75 0 016.5 3.75h11a2.75 2.75 0 012.75 2.75v11a2.75 2.75 0 01-2.75 2.75z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.155 7.887a1.604 1.604 0 11-2.268 2.268 1.604 1.604 0 012.268-2.268M20.25 10.267a8.919 8.919 0 00-1.26-.1 8.823 8.823 0 00-8.823 8.823c0 .429.04.848.1 1.26"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgAddNew.propTypes = { currentColor: PropTypes.string };SvgAddNew.defaultProps = { stroke: '#212121' };export default SvgAddNew;
