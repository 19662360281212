import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgDelete = props => (
	<svg width="1rem" height="1rem" fill="none" viewBox="0 0 28 36" {...props}>
		<path
			d="M2 32c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V8H2v24zM28 2h-7l-2-2H9L7 2H0v4h28V2z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgDelete.propTypes = {
	currentColor: PropTypes.string
};

SvgDelete.defaultProps = {
	fill: colors.black
};

export default SvgDelete;
