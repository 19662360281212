import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const TermsNew2 = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M42 20C42.2627 20.0001 42.5228 19.9484 42.7654 19.8479C43.0081 19.7474 43.2286 19.6001 43.4144 19.4144C43.6001 19.2286 43.7474 19.0081 43.8479 18.7654C43.9484 18.5228 44.0001 18.2627 44 18V12C44.0002 11.5803 43.8682 11.1713 43.6228 10.8309C43.3774 10.4905 43.031 10.236 42.6328 10.1035L24.6328 4.10352C24.222 3.9668 23.778 3.9668 23.3672 4.10352L5.36718 10.1035C4.96899 10.236 4.62262 10.4905 4.37721 10.8309C4.13181 11.1713 3.99983 11.5803 4 12V18C3.99993 18.2627 4.05162 18.5228 4.1521 18.7654C4.25259 19.0081 4.3999 19.2286 4.58563 19.4144C4.77137 19.6001 4.99187 19.7474 5.23455 19.8479C5.47723 19.9484 5.73734 20.0001 6 20H8V34.3685C6.83347 34.7794 5.82273 35.5413 5.10653 36.5496C4.39034 37.5579 4.0038 38.7632 4 40V44C3.99993 44.2627 4.05162 44.5228 4.1521 44.7654C4.25259 45.0081 4.3999 45.2286 4.58563 45.4144C4.77137 45.6001 4.99187 45.7474 5.23455 45.8479C5.47723 45.9484 5.73734 46.0001 6 46H42C42.2627 46.0001 42.5228 45.9484 42.7654 45.8479C43.0081 45.7474 43.2286 45.6001 43.4144 45.4144C43.6001 45.2286 43.7474 45.0081 43.8479 44.7654C43.9484 44.5228 44.0001 44.2627 44 44V40C43.9962 38.7632 43.6097 37.5579 42.8935 36.5496C42.1773 35.5413 41.1665 34.7794 40 34.3685V20H42ZM40 42H8V40C8.00053 39.4697 8.21141 38.9613 8.58637 38.5864C8.96133 38.2114 9.46973 38.0005 10 38H38C38.5303 38.0005 39.0387 38.2114 39.4136 38.5864C39.7886 38.9613 39.9995 39.4697 40 40V42ZM12 34V20H16V34H12ZM20 34V20H28V34H20ZM32 34V20H36V34H32ZM8 16V13.4414L24 8.1074L40 13.4414V16H8Z"
			fill={props.currentColor}
		/>
	</svg>
);

TermsNew2.propTypes = {
	currentColor: PropTypes.string
};

TermsNew2.defaultProps = {
	fill: colors.black
};

export default TermsNew2;
