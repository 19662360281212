import * as React from "react";
import PropTypes from 'prop-types';

function SvgQuestion(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 24" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M12.092 20.25v0A8.25 8.25 0 013.842 12v0a8.25 8.25 0 018.25-8.25v0a8.25 8.25 0 018.25 8.25v0a8.25 8.25 0 01-8.25 8.25z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.091 13.146v-.229c0-.749.463-1.155.927-1.467.453-.305.906-.703.906-1.436a1.833 1.833 0 10-3.666 0M12.09 15.667a.23.23 0 10.002.458.23.23 0 00-.002-.458"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgQuestion.propTypes = { currentColor: PropTypes.string };SvgQuestion.defaultProps = { stroke: '#3E21DE' };export default SvgQuestion;
