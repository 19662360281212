import * as React from "react";
import PropTypes from 'prop-types';

function SvgSharing(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 16" fill="none" {...props}>
      <path
        d="M2.389 12.278v0a2.445 2.445 0 012.444-2.445h2.445a2.445 2.445 0 012.444 2.445v0M7.567 4.349a2.139 2.139 0 11-3.024 3.024A2.139 2.139 0 017.567 4.35M10.943 9.222h1.834c1.012 0 1.833.82 1.833 1.834v0M12.942 4.78a1.528 1.528 0 11-2.16 2.161 1.528 1.528 0 012.16-2.16"
        stroke={props.currentColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgSharing.propTypes = { currentColor: PropTypes.string };SvgSharing.defaultProps = { stroke: '#fff' };export default SvgSharing;
