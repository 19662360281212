import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgFileDownload = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path d="M38 18h-8V6H18v12h-8l14 14 14-14zM10 36v4h28v-4H10z" fill={props.currentColor} />
	</svg>
);

SvgFileDownload.propTypes = {
	currentColor: PropTypes.string
};

SvgFileDownload.defaultProps = {
	fill: colors.black
};

export default SvgFileDownload;
