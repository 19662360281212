import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgArrowBack = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path d="M40 22H15.66l11.17-11.17L24 8 8 24l16 16 2.83-2.83L15.66 26H40v-4z" fill={props.currentColor} />
	</svg>
);

SvgArrowBack.propTypes = {
	currentColor: PropTypes.string
};

SvgArrowBack.defaultProps = {
	fill: colors.black
};

export default SvgArrowBack;
