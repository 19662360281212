import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgCheck = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path d="M18 32.34L9.66 24l-2.83 2.83L18 38l24-24-2.83-2.83L18 32.34z" fill={props.currentColor} />
	</svg>
);

SvgCheck.propTypes = {
	currentColor: PropTypes.string
};

SvgCheck.defaultProps = {
	fill: colors.black
};

export default SvgCheck;
