import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgFolder = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M20 8H8c-2.21 0-3.98 1.79-3.98 4L4 36c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V16c0-2.21-1.79-4-4-4H24l-4-4z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgFolder.propTypes = {
	currentColor: PropTypes.string
};

SvgFolder.defaultProps = {
	fill: colors.black
};

export default SvgFolder;
