import * as React from "react";
import PropTypes from 'prop-types';

function SvgBin(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M3.723 4.944h8.555M11.667 4.944v6.723c0 .675-.547 1.222-1.222 1.222H5.556a1.222 1.222 0 01-1.222-1.222V4.944M9.834 2.958H6.167M6.778 7.389v3.055M9.223 7.389v3.055"
        stroke={props.currentColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgBin.propTypes = { currentColor: PropTypes.string };SvgBin.defaultProps = { stroke: '#fff' };export default SvgBin;
