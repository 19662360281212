import * as React from "react";
import PropTypes from 'prop-types';

function SvgAttachment(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M11.11 16h9.778M13.08 20.889h-2.886A5.195 5.195 0 015 15.694v0a5.195 5.195 0 015.194-5.194h2.886M18.92 20.889h2.886A5.195 5.195 0 0027 15.694v0a5.195 5.195 0 00-5.194-5.194H18.92"
        stroke={props.currentColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgAttachment.propTypes = { currentColor: PropTypes.string };SvgAttachment.defaultProps = { stroke: '#212121' };export default SvgAttachment;
