import * as React from "react";
import PropTypes from 'prop-types';

function SvgMobile(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M15.667 20.25H8.333A1.833 1.833 0 016.5 18.417V5.583c0-1.013.82-1.833 1.833-1.833h7.334c1.013 0 1.833.82 1.833 1.833v12.834c0 1.013-.82 1.833-1.833 1.833z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16.813a.23.23 0 10.002.458.23.23 0 00-.003-.459M10.854 6.5h2.292"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgMobile.propTypes = { currentColor: PropTypes.string };SvgMobile.defaultProps = { stroke: '#212121' };export default SvgMobile;
