// import React, { useState } from "react";
import React, { useState } from 'react';
import styled from 'styled-components';
import { Input, EyeNew as Visible, EyeOff as Hidden } from 'desisystem';
import { colors, primary } from '../guidelines/shared';

const PasswordInput = styled(Input)`
  label {
    font-size: ${props => props.size};
  }
  && {
    ${props =>
      props.hasError &&
      `
  border-color: ${colors.red};
  outline-color: ${colors.red};
  &:focus,
  &:active {
    outline-color: ${colors.red};
    border-color: ${colors.red};
  }
`}
  }
`;

const PasswordField = ({ handleChange, label, placeholder, name, hasError, register, size }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div>
      <PasswordInput
        hasError={hasError}
        label={label}
        type={isVisible ? 'text' : 'password'}
        placeholder={placeholder}
        onChange={e => handleChange(e.target.value)}
        name={name}
        size={size}
        validate={register({
          minLength: 8,
          required: true
        })}
        icon={
          <div onClick={() => setIsVisible(!isVisible)}>
            {isVisible ? (
              <Visible width={30} height={21} fill={primary[10]} />
            ) : (
              <Hidden width={30} height={21} fill={primary[10] + '55'} />
            )}
          </div>
        }
      />
      {hasError && <span className="error-msg">Must be at least 8 characters long</span>}
    </div>
  );
};

export default PasswordField;
