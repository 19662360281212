import * as React from "react";
import PropTypes from 'prop-types';

function SvgDashboard(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 4.001V6.44M21 13h-2M3 13h2M18.36 6.64l-4.956 4.956M11.981 11a2 2 0 11.002 3.999A2 2 0 0111.98 11zM5.64 6.64l2.12 2.12"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.24 17.24l2.12 2.12.004.004a9 9 0 000-12.728 9 9 0 00-12.728 0 9 9 0 000 12.728l.004-.004 2-2"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgDashboard.propTypes = { currentColor: PropTypes.string };SvgDashboard.defaultProps = { stroke: '#E0E0E0' };export default SvgDashboard;
