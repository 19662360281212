import * as React from "react";
import PropTypes from 'prop-types';

function SvgBacktoTop(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M6.5 12.917v1.725c0 2.58 2.063 4.691 4.583 4.691v0c2.521 0 4.584-2.11 4.584-4.691V4.622"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8.29l3.667-3.667 3.666 3.666"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgBacktoTop.propTypes = { currentColor: PropTypes.string };SvgBacktoTop.defaultProps = { stroke: '#212121' };export default SvgBacktoTop;
