import * as React from "react";
import PropTypes from 'prop-types';

function SvgSubsIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M17.417 17.417H7.333A1.833 1.833 0 015.5 15.583V9.168c0-1.013.82-1.833 1.833-1.833h10.084c1.013 0 1.833.82 1.833 1.833v6.417c0 1.012-.82 1.833-1.833 1.833z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 11h13.75M5.5 14.667h-.917a1.833 1.833 0 01-1.833-1.833V6.417c0-1.013.82-1.833 1.833-1.833h10.084c1.013 0 1.833.82 1.833 1.833v.917M11 14.667H8.25"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgSubsIcon.propTypes = { currentColor: PropTypes.string };SvgSubsIcon.defaultProps = { stroke: '#212121' };export default SvgSubsIcon;
