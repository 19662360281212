import * as React from "react";
import PropTypes from 'prop-types';

function SvgTerms(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M42 20a1.998 1.998 0 002-2v-6a1.997 1.997 0 00-1.367-1.896l-18-6a2.004 2.004 0 00-1.266 0l-18 6A1.998 1.998 0 004 12v6a1.999 1.999 0 002 2h2v14.368A5.99 5.99 0 004 40v4a1.999 1.999 0 002 2h36a1.998 1.998 0 002-2v-4a5.99 5.99 0 00-4-5.632V20h2zm-2 22H8v-2a2.002 2.002 0 012-2h28a2.002 2.002 0 012 2v2zm-28-8V20h4v14h-4zm8 0V20h8v14h-8zm12 0V20h4v14h-4zM8 16v-2.559l16-5.334 16 5.334V16H8z"
        fill={props.currentColor}
      />
    </svg>
  );
}

SvgTerms.propTypes = { currentColor: PropTypes.string };SvgTerms.defaultProps = { fill: '#000' };export default SvgTerms;
