import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgKey = props => (
	<svg width="1em" height="1em" viewBox="0 0 16 8" fill="none" {...props}>
		<path
			d="M8.43317 2.66667C7.88317 1.11333 6.4065 0 4.6665 0C2.4565 0 0.666504 1.79 0.666504 4C0.666504 6.21 2.4565 8 4.6665 8C6.4065 8 7.88317 6.88667 8.43317 5.33333H11.3332V8H13.9998V5.33333H15.3332V2.66667H8.43317ZM4.6665 5.33333C3.92984 5.33333 3.33317 4.73667 3.33317 4C3.33317 3.26333 3.92984 2.66667 4.6665 2.66667C5.40317 2.66667 5.99984 3.26333 5.99984 4C5.99984 4.73667 5.40317 5.33333 4.6665 5.33333Z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgKey.propTypes = {
	currentColor: PropTypes.string
};

SvgKey.defaultProps = {
	fill: colors.brandBlue
};

export default SvgKey;
