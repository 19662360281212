import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgLink = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M7.8 24c0-3.42 2.78-6.2 6.2-6.2h8V14h-8C8.48 14 4 18.48 4 24s4.48 10 10 10h8v-3.8h-8c-3.42 0-6.2-2.78-6.2-6.2zm8.2 2h16v-4H16v4zm18-12h-8v3.8h8c3.42 0 6.2 2.78 6.2 6.2 0 3.42-2.78 6.2-6.2 6.2h-8V34h8c5.52 0 10-4.48 10-10s-4.48-10-10-10z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgLink.propTypes = {
	currentColor: PropTypes.string
};

SvgLink.defaultProps = {
	fill: colors.black
};

export default SvgLink;
