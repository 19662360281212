import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgEye = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M43.74 23.0002C42.46 20.7802 35.42 9.64023 23.46 10.0002C12.4 10.2802 6 20.0002 4.26 23.0002C4.08446 23.3043 3.99205 23.6492 3.99205 24.0002C3.99205 24.3513 4.08446 24.6962 4.26 25.0002C5.52 27.1802 12.26 38.0002 24.04 38.0002H24.54C35.6 37.7202 42.02 28.0002 43.74 25.0002C43.9155 24.6962 44.0079 24.3513 44.0079 24.0002C44.0079 23.6492 43.9155 23.3043 43.74 23.0002ZM24.44 34.0002C15.82 34.2002 10.2 26.8202 8.44 24.0002C10.44 20.7802 15.66 14.2002 23.66 14.0002C32.24 13.7802 37.88 21.1802 39.66 24.0002C37.6 27.2202 32.44 33.8002 24.44 34.0002Z"
			fill={props.currentColor}
		/>
		<path
			d="M24 17C22.6155 17 21.2622 17.4105 20.111 18.1797C18.9599 18.9489 18.0627 20.0421 17.5328 21.3212C17.003 22.6003 16.8644 24.0078 17.1345 25.3656C17.4046 26.7235 18.0713 27.9708 19.0503 28.9497C20.0292 29.9287 21.2765 30.5954 22.6344 30.8655C23.9922 31.1356 25.3997 30.997 26.6788 30.4672C27.9579 29.9373 29.0511 29.0401 29.8203 27.889C30.5895 26.7378 31 25.3845 31 24C31 22.1435 30.2625 20.363 28.9497 19.0503C27.637 17.7375 25.8565 17 24 17ZM24 27C23.4067 27 22.8266 26.8241 22.3333 26.4944C21.8399 26.1648 21.4554 25.6962 21.2284 25.1481C21.0013 24.5999 20.9419 23.9967 21.0576 23.4147C21.1734 22.8328 21.4591 22.2982 21.8787 21.8787C22.2982 21.4591 22.8328 21.1734 23.4147 21.0576C23.9967 20.9419 24.5999 21.0013 25.1481 21.2284C25.6962 21.4554 26.1648 21.8399 26.4944 22.3333C26.8241 22.8266 27 23.4067 27 24C27 24.7956 26.6839 25.5587 26.1213 26.1213C25.5587 26.6839 24.7956 27 24 27Z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgEye.propTypes = {
	currentColor: PropTypes.string
};

SvgEye.defaultProps = {
	fill: colors.black
};

export default SvgEye;
