import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const KeyNew = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M43.42 13.0599L40.58 10.2399L43.42 7.41988C43.7966 7.04328 44.0082 6.53249 44.0082 5.99988C44.0082 5.46728 43.7966 4.95649 43.42 4.57988C43.0434 4.20327 42.5326 3.9917 42 3.9917C41.4674 3.9917 40.9566 4.20327 40.58 4.57988L19.5 25.6599C17.4321 24.2956 14.9313 23.7474 12.4823 24.1217C10.0333 24.4959 7.81045 25.7659 6.24438 27.6856C4.67832 29.6052 3.88059 32.0379 4.00576 34.5122C4.13094 36.9864 5.17011 39.3262 6.92192 41.078C8.67373 42.8298 11.0135 43.869 13.4877 43.9941C15.962 44.1193 18.3946 43.3216 20.3143 41.7555C22.234 40.1894 23.504 37.9665 23.8782 35.5175C24.2525 33.0685 23.7043 30.5678 22.34 28.4999L32.1 18.7199L34.92 21.5599C35.1069 21.7452 35.3285 21.8919 35.5722 21.9914C35.8159 22.091 36.0768 22.1414 36.34 22.1399C36.6032 22.1414 36.8642 22.091 37.1078 21.9914C37.3515 21.8919 37.5731 21.7452 37.76 21.5599C37.9475 21.374 38.0963 21.1528 38.1978 20.909C38.2993 20.6653 38.3516 20.4039 38.3516 20.1399C38.3516 19.8759 38.2993 19.6144 38.1978 19.3707C38.0963 19.127 37.9475 18.9058 37.76 18.7199L34.92 15.9999L37.76 13.1599L40.58 15.9999C40.7669 16.1852 40.9885 16.3319 41.2322 16.4314C41.4759 16.531 41.7368 16.5814 42 16.5799C42.2632 16.5814 42.5242 16.531 42.7678 16.4314C43.0115 16.3319 43.2331 16.1852 43.42 15.9999C43.623 15.8126 43.785 15.5853 43.8959 15.3323C44.0067 15.0793 44.0639 14.8061 44.0639 14.5299C44.0639 14.2537 44.0067 13.9805 43.8959 13.7275C43.785 13.4745 43.623 13.2472 43.42 13.0599ZM14 39.9999C12.8133 39.9999 11.6533 39.648 10.6666 38.9887C9.67989 38.3294 8.91086 37.3923 8.45673 36.296C8.00261 35.1996 7.88379 33.9932 8.1153 32.8293C8.34681 31.6655 8.91825 30.5964 9.75737 29.7572C10.5965 28.9181 11.6656 28.3467 12.8295 28.1152C13.9934 27.8837 15.1998 28.0025 16.2961 28.4566C17.3925 28.9107 18.3295 29.6798 18.9888 30.6665C19.6481 31.6532 20 32.8132 20 33.9999C20 35.5912 19.3679 37.1173 18.2427 38.2425C17.1174 39.3677 15.5913 39.9999 14 39.9999Z"
			fill={props.currentColor}
		/>
	</svg>
);

KeyNew.propTypes = {
	currentColor: PropTypes.string
};

KeyNew.defaultProps = {
	fill: colors.black
};

export default KeyNew;
