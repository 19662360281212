import * as React from "react";
import PropTypes from 'prop-types';

function SvgLearn(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M42.98 20.38l-2-1.1-18-10h-.22a2.118 2.118 0 00-.38-.12h-.74a2.34 2.34 0 00-.4.12h-.22l-18 10a2 2 0 000 3.48L8 25.52V35a6 6 0 006 6h16a6 6 0 006-6v-9.48l4-2.24V29a2 2 0 004 0v-6.88a2 2 0 00-1.02-1.74zM32 35a2 2 0 01-2 2H14a2 2 0 01-2-2v-7.26l9.02 5 .3.12h.18c.166.021.334.021.5 0 .166.021.334.021.5 0h.18a.94.94 0 00.3-.12l9.02-5V35zm-10-6.28L8.12 21 22 13.28 35.88 21 22 28.72z"
        fill={props.currentColor}
      />
    </svg>
  );
}

SvgLearn.propTypes = { currentColor: PropTypes.string };SvgLearn.defaultProps = { fill: '#000' };export default SvgLearn;
