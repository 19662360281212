import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Body, SmallBody } from '../guidelines/typography';
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import SvgCardMenu from '../guidelines/icons/nav/CardMenu';

const StyledLink = styled(Link)`
	background-color: #ffffff;
	color: #000;
	border-radius: 0px;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	align-items: center;
	color: #172b4d;
	fill: #ffffff;
	padding: 8px 12px 7px 12px;
	width: auto;
	text-decoration: none;

	&:hover {
		background: #f4f5f7;
	}
`;

const Dropdown = ({ items }) => {
	return (
		<div>
			<DropdownMenu
				trigger={<SvgCardMenu width={25} height={25} />}
				onOpenChange={e => console.log('dropdown opened', e)}
			>
				<DropdownItemGroup>
					{items.map((d, i) => {
						return (
							<StyledLink to={d.to} key={i}>
								{d.text}
							</StyledLink>
						);
					})}
				</DropdownItemGroup>
			</DropdownMenu>
		</div>
	);
};

export default Dropdown;
