import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgPayment = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M40 8H8c-2.21 0-3.98 1.79-3.98 4L4 36c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V12c0-2.21-1.79-4-4-4zm0 28H8V24h32v12zm0-20H8v-4h32v4z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgPayment.propTypes = {
	currentColor: PropTypes.string
};

SvgPayment.defaultProps = {
	fill: colors.black
};

export default SvgPayment;
