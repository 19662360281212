import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { darkmode, lightmode } from './shared';
import { radius, colors } from './shared';

// paragraph
export const Body = styled.p`
  font-family: inherit;
  font-size: ${props => props.size};
  font-weight: ${props => props.weight};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: ${props => props.color};
  margin: 0;


  ${props =>
    props.type === 'lt' &&
    `
    font-size: 22px;
  `}

  ${props =>
    props.type === 'ltb' &&
    `
    font-weight: bold;
    font-size: 22px;
  `}

  ${props =>
    props.type === 'ntb' &&
    `
    font-weight: bold;
  `}

  ${props =>
    props.type === 'st' &&
    `
    font-size: 12px;
  `}

  ${props =>
    props.type === 'stb' &&
    `
    font-weight: bold;
    font-size: 12px;
  `}

  ${props =>
    props.type === 'caption' &&
    `
    font-size: 14px;
  `}

  ${props =>
    props.type === 'btp' &&
    `
    font-size: 18px;
  `}

  ${props =>
    props.type === 'btt' &&
    `
    font-size: 18px;
  `}
`;

Body.propTypes = {
  weight: PropTypes.oneOf(['normal', 'bold', 'italic']),
  size: PropTypes.oneOf(['22px', '18px', '16px', '14px', '12px']),
  color: PropTypes.string
};

Body.defaultProps = {
  weight: 'normal',
  size: '16px',
  color: colors.black
};

// Heading 1
export const H1 = styled.h1`
  font-family: inherit;
  font-size: 80px;
  font-weight: ${props => props.weight};
  font-style: normal;
  color: ${props => props.color};
  margin: 0;
`;

H1.propTypes = {
  weight: PropTypes.oneOf(['normal', 'bold', 'italic']),
  color: PropTypes.string
};

H1.defaultProps = {
  weight: 'normal',
  color: colors.black
};

// Heading 2
export const H2 = styled.h2`
  font-family: inherit;
  font-size: 64px;
  font-weight: ${props => props.weight};
  font-style: normal;
  color: ${props => props.color};
  margin: 0;
`;

H2.propTypes = {
  weight: PropTypes.oneOf(['normal', 'bold', 'italic']),
  color: PropTypes.string
};

H2.defaultProps = {
  weight: 'normal',
  color: colors.black
};

// Heading 3
export const H3 = styled.h3`
  font-family: inherit;
  font-size: 48px;
  font-weight: ${props => props.weight};
  font-style: normal;
  color: ${props => props.color};
  margin: 0;
`;

H3.propTypes = {
  weight: PropTypes.oneOf(['normal', 'bold', 'italic']),
  color: PropTypes.string
};

H3.defaultProps = {
  weight: 'normal',
  color: colors.black
};

// Heading 4
export const H4 = styled.h4`
  font-family: inherit;
  font-size: 34px;
  font-weight: ${props => props.weight};
  font-style: normal;
  color: ${props => props.color};
  margin: 0;
}
`;

H4.propTypes = {
  weight: PropTypes.oneOf(['normal', 'bold', 'italic']),
  color: PropTypes.string
};

H4.defaultProps = {
  weight: 'normal',
  color: colors.black
};
