import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const UserNew = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<ellipse cx="24" cy="13" rx="8" ry="9" stroke={props.currentColor} strokeWidth="4" />
		<path
			d="M40 38C40 45 32 43 24 43C16 43 8 45 8 38C8 34 15.1634 29 24 29C32.8366 29 40 34 40 38Z"
			stroke={props.currentColor}
			strokeWidth="4"
		/>
	</svg>
);

UserNew.propTypes = {
	currentColor: PropTypes.string
};

UserNew.defaultProps = {
	stroke: '#262F56'
};

export default UserNew;
