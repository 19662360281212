import * as React from "react";
import PropTypes from 'prop-types';

function SvgTriagleWarning(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
      <path
        d="M20 21.711v-5.714M19.999 26.303a.382.382 0 10.003.764.382.382 0 00-.003-.764"
        stroke={props.currentColor}
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M23.1 7.99l11.702 20.478c1.36 2.38-.359 5.343-3.1 5.343H8.3c-2.743 0-4.461-2.962-3.1-5.343L16.9 7.99c1.37-2.4 4.83-2.4 6.2 0z"
        stroke={props.currentColor}
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgTriagleWarning.propTypes = { currentColor: PropTypes.string };SvgTriagleWarning.defaultProps = { stroke: '#EB4B4B' };export default SvgTriagleWarning;
