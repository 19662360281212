import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgFavoriteBorder = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M33 6c-3.48 0-6.82 1.62-9 4.17C21.82 7.62 18.48 6 15 6 8.83 6 4 10.83 4 17c0 7.55 6.8 13.72 17.1 23.07L24 42.7l2.9-2.63C37.2 30.72 44 24.55 44 17c0-6.17-4.83-11-11-11zm-8.79 31.11l-.21.19-.21-.19C14.28 28.48 8 22.78 8 17c0-3.99 3.01-7 7-7 3.08 0 6.08 1.99 7.13 4.72h3.73C26.92 11.99 29.92 10 33 10c3.99 0 7 3.01 7 7 0 5.78-6.28 11.48-15.79 20.11z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgFavoriteBorder.propTypes = {
	currentColor: PropTypes.string
};

SvgFavoriteBorder.defaultProps = {
	fill: colors.black
};

export default SvgFavoriteBorder;
