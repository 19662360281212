import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { colors, gradient } from '../guidelines/shared';

const CustomRadio = withStyles({
  root: {
    color: colors.primary,
    '&$checked': {
      color: colors.primary
    }
  },
  checked: {}
})(props => <Radio color="default" {...props} />);

const CustomRadioGroup = styled(RadioGroup)`
  display: 'flex';
  flex-direction: ${props => props.direction}!important;
`;

const FormControlLabelPosition = props => {
  const { radios } = props;

  function getTest() {
    return radios[0].label;
  }

  return (
    <FormControl component="fieldset">
      <CustomRadioGroup aria-label="position" defaultValue={getTest()} name="position" {...props} row>
        {radios.map((ra, i) => {
          return (
            <FormControlLabel
              key={i}
              control={<CustomRadio value={ra.label} name="radio-button-demo" />}
              label={ra.label}
              labelPlacement="end"
            />
          );
        })}
      </CustomRadioGroup>
    </FormControl>
  );
};

export default FormControlLabelPosition;
