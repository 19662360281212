import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgHidden = props => (
	<svg width="1em" height="1em" viewBox="0 0 44 34" fill="none" {...props}>
		<path
			d="M22 8c5.52 0 10 4.48 10 10 0 1.29-.26 2.52-.71 3.65l5.85 5.85c3.02-2.52 5.4-5.78 6.87-9.5C40.54 9.22 32.01 3 22 3c-2.8 0-5.48.5-7.97 1.4l4.32 4.31C19.48 8.27 20.71 8 22 8zM2 2.55l4.56 4.56.91.91C4.17 10.6 1.56 14.03 0 18c3.46 8.78 12 15 22 15 3.1 0 6.06-.6 8.77-1.69l.85.85L37.45 38 40 35.46 4.55 0 2 2.55zM13.06 13.6l3.09 3.09c-.09.43-.15.86-.15 1.31 0 3.31 2.69 6 6 6 .45 0 .88-.06 1.3-.15l3.09 3.09C25.06 27.6 23.58 28 22 28c-5.52 0-10-4.48-10-10 0-1.58.4-3.06 1.06-4.4zm8.61-1.57l6.3 6.3L28 18c0-3.31-2.69-6-6-6l-.33.03z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgHidden.propTypes = {
	currentColor: PropTypes.string
};

SvgHidden.defaultProps = {
	fill: colors.black
};

export default SvgHidden;
