import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const Share = props => (
	<svg width="1em" height="1em" viewBox="0 0 50 50" fill="none" {...props}>
		<path
			d="M36 32.17C34.48 32.17 33.11 32.76 32.07 33.71L17.82 25.4C17.93 24.95 18 24.48 18 24C18 23.52 17.93 23.05 17.82 22.6L31.92 14.37C32.99 15.37 34.42 15.99 36 15.99C39.31 15.99 42 13.3 42 9.98999C42 6.67999 39.31 3.98999 36 3.98999C32.69 3.98999 30 6.67999 30 9.98999C30 10.47 30.07 10.94 30.18 11.39L16.08 19.62C15.01 18.62 13.58 18 12 18C8.69 18 6 20.69 6 24C6 27.31 8.69 30 12 30C13.58 30 15.01 29.38 16.08 28.38L30.33 36.69C30.23 37.11 30.17 37.55 30.17 38C30.17 41.22 32.78 43.83 36 43.83C39.22 43.83 41.83 41.22 41.83 38C41.83 34.78 39.22 32.17 36 32.17Z"
			fill={props.currentColor}
		/>
	</svg>
);

Share.propTypes = {
	currentColor: PropTypes.string
};

Share.defaultProps = {
	fill: colors.black
};

export default Share;
