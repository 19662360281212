import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { radius, brandColors, shadows, colors } from "../guidelines/shared";
import Button from "../elements/Button";

const StyledCard = styled.div`
	padding: 32px;
`;

const BottomCard = props => {
	return <StyledCard {...props}></StyledCard>;
};

export default BottomCard;
