export const bgColors = {
	darkPurple: '#1D0F68',
	lightGrey: '#FCFCFC'
};

export const colors = {
	primary: '#3E21DE',
	grey: '#E2E0EC',
	black: '#212121',
	white: '#FFFFFF',
	purple: '#5940e3',
	lightPurple: '#C3BAF5',
	yellow: '#FFFB94',
	green: '#58B149',
	red: '#D53C3C',
	blue: '#5940e3',
	brandBlue: '#3E21DE'
};

// for dump
export const brandColors = {
	main: '#3E21DE'
};

// export const fonts = {
// 	roboto: 'Roboto, sans-serif'
// };

export const primary = [
	'#F1EFFD',
	'#C3BAF5',
	'#9B8CEE',
	'#7864E8',
	'#5940E3',
	'#3E21DE',
	'#351CBF',
	'#2E18A4',
	'#28158D',
	'#221279',
	'#1D0F68'
];

export const neutral = [
	'#FAFAFA',
	'#F5F5F5',
	'#EEEEEE',
	'#E0E0E0',
	'#BDBDBD',
	'#9E9E9E',
	'#757575',
	'#616161',
	'#424242',
	'#212121'
];
export const success = ['#7FDD6F', '#62C851', '#58B149', '#3DA62B', '#2B9519'];
export const error = ['#FF7D7D', '#EB4B4B', '#D43C3C', '#BD2B2B', '#991616'];

export const gradient = {
	primary_gradient: 'linear-gradient(90deg, #5940E3 0%, #927FFF 100%)'
};

export const hoverCheck = {
	hover: 'rgba(89, 64, 227, 0.08)'
};

export const shadows = {
	darkShadow: '0px 10px 20px rgba(62, 33, 222, 0.1)',
	darkShadowHover: '0px 20px 30px rgba(62, 33, 222, 0.2)',
	lightShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
	lightShadowHover: ' 0px 20px 30px rgba(0, 0, 0, 0.2)',
	cardShadow: '0px 20px 40px rgba(0, 0, 0, 0.1)',
	cardShadowHover: '0px 30px 60px rgba(0, 0, 0, 0.35)'
};

export const radius = {
	soft: '4px',
	medium: '15px',
	semi_hard: '20px',
	hard: '30px'
};

export const darkmode = {
	color: 'black'
};

export const lightmode = {
	color: 'white'
};
