import * as React from "react";
import PropTypes from 'prop-types';

function SvgBook(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 31" fill="none" {...props}>
      <path
        d="M26.222 24.666H6.362"
        stroke={props.currentColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M6.361 29.25h18.334c.843 0 1.527-.684 1.527-1.527v-7.639H6.362a4.583 4.583 0 00-4.584 4.583v0a4.583 4.583 0 004.583 4.584z"
        stroke={props.currentColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.472 7.862h6.111M12.472 13.973h6.111M6.361 1.75v18.333M1.778 24.667V6.333A4.583 4.583 0 016.36 1.75h18.334c.843 0 1.527.684 1.527 1.528v16.805"
        stroke={props.currentColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgBook.propTypes = { currentColor: PropTypes.string };SvgBook.defaultProps = { stroke: '#212121' };export default SvgBook;
