import * as React from "react";
import PropTypes from 'prop-types';

function SvgDownload(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 16.583V3.75M19.333 20.25H4.667M16.583 12L12 16.584 7.416 12"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgDownload.propTypes = { currentColor: PropTypes.string };SvgDownload.defaultProps = { stroke: '#212121' };export default SvgDownload;
