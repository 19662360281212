import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgMenu = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path d="M6 36h36v-4H6v4zm0-10h36v-4H6v4zm0-14v4h36v-4H6z" fill={props.currentColor} />
	</svg>
);

SvgMenu.propTypes = {
	currentColor: PropTypes.string
};

SvgMenu.defaultProps = {
	fill: colors.black
};

export default SvgMenu;
