import * as React from "react";
import PropTypes from 'prop-types';

function SvgOriginalImage(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M9.493 9.007a.344.344 0 11-.486.486.344.344 0 01.486-.486"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M12 20.25v0A8.25 8.25 0 013.75 12v0A8.25 8.25 0 0112 3.75v0A8.25 8.25 0 0120.25 12v0A8.25 8.25 0 0112 20.25z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.083 15.667l3.936-3.936a.916.916 0 011.296 0l3.338 3.339"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.07 19.653l-5.172-5.172a.916.916 0 00-1.296 0L5.73 17.353"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgOriginalImage.propTypes = { currentColor: PropTypes.string };SvgOriginalImage.defaultProps = { stroke: '#212121' };export default SvgOriginalImage;
