import * as React from "react";
import PropTypes from 'prop-types';

function SvgPlus(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 7v10M17 12H7"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgPlus.propTypes = { currentColor: PropTypes.string };SvgPlus.defaultProps = { stroke: '#212121' };export default SvgPlus;
