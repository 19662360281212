import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgCloud = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M38.71 20.07C37.35 13.19 31.28 8 24 8c-5.78 0-10.79 3.28-13.3 8.07C4.69 16.72 0 21.81 0 28c0 6.63 5.37 12 12 12h26c5.52 0 10-4.48 10-10 0-5.28-4.11-9.56-9.29-9.93z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgCloud.propTypes = {
	currentColor: PropTypes.string
};

SvgCloud.defaultProps = {
	fill: colors.black
};

export default SvgCloud;
