import * as React from "react";
import PropTypes from 'prop-types';

function SvgUploadImage(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M38 26a2 2 0 00-2 2v.76l-2.96-2.96a5.58 5.58 0 00-7.86 0l-1.4 1.4-4.96-4.96a5.7 5.7 0 00-7.86 0L8 25.2V14a2 2 0 012-2h14a2 2 0 000-4H10a6 6 0 00-6 6v24a6 6 0 006 6h24a6 6 0 006-6V28a2 2 0 00-2-2zM10 40a2 2 0 01-2-2v-7.14l5.8-5.8a1.58 1.58 0 012.18 0l6.34 6.34 8.6 8.6H10zm26-2a1.78 1.78 0 01-.36 1.06L26.62 30l1.4-1.4a1.54 1.54 0 012.2 0L36 34.42V38zm9.42-29.42l-6-6a2 2 0 00-.66-.42 2 2 0 00-1.52 0 2 2 0 00-.66.42l-6 6a2.008 2.008 0 102.84 2.84L36 8.82V20a2 2 0 004 0V8.82l2.58 2.6a2.002 2.002 0 002.84 0 2 2 0 000-2.84z"
        fill={props.currentColor}
      />
    </svg>
  );
}

SvgUploadImage.propTypes = { currentColor: PropTypes.string };SvgUploadImage.defaultProps = { fill: '#000' };export default SvgUploadImage;
