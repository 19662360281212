import * as React from "react";
import PropTypes from 'prop-types';

function SvgRedirect(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 8 8" fill="none" {...props}>
      <path
        d="M6.4 3.528a.4.4 0 00-.4.4V6.8a.4.4 0 01-.4.4H1.2a.4.4 0 01-.4-.4V2.4a.4.4 0 01.4-.4h2.872a.4.4 0 100-.8H1.2A1.2 1.2 0 000 2.4v4.4A1.2 1.2 0 001.2 8h4.4a1.2 1.2 0 001.2-1.2V3.928a.4.4 0 00-.4-.4zM7.968.248A.4.4 0 007.6 0H5.2a.4.4 0 100 .8h1.436l-4.12 4.116a.4.4 0 00.13.656.4.4 0 00.438-.088L7.2 1.364V2.8a.4.4 0 10.8 0V.4a.4.4 0 00-.032-.152z"
        fill={props.currentColor}
      />
    </svg>
  );
}

SvgRedirect.propTypes = { currentColor: PropTypes.string };SvgRedirect.defaultProps = { fill: '#3E21DE' };export default SvgRedirect;
