import * as React from "react";

function SvgInfinity(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 150.223 150.223" {...props}>
      <path d="M115.885 38.631c-21.312 0-35.416 17.425-47.859 32.802-10.631 13.136-20.67 25.54-32.897 25.54-12.709 0-20.512-13.062-20.512-22.426 0-8.682 8.56-21.299 21.976-21.299 13.018 0 19.8 10.419 20.142 10.953a7.307 7.307 0 0010.017 2.395 7.308 7.308 0 002.438-10.044c-.446-.73-11.167-17.92-32.597-17.92C15.029 38.632 0 57.562 0 74.548c0 17.521 14.427 37.043 35.128 37.043 19.199 0 31.94-15.74 44.259-30.963 11.396-14.078 22.161-27.379 36.498-27.379 8.53 0 14.135 2.894 17.131 8.84 2.596 5.16 2.594 10.999 2.592 13.022 0 1.16-.613 6.978-3.451 12.217-3.236 5.968-7.83 8.746-14.469 8.746-13.369 0-20.592-8.69-23.312-11.963-.707-.852-1.216-1.465-1.896-2.047a7.312 7.312 0 00-10.306.795 7.305 7.305 0 00.478 10.014c.132.15.291.347.48.576 3.545 4.266 14.324 17.24 34.553 17.24 24.586 0 32.537-23.973 32.537-35.678.002-7.455-1.394-14.013-4.154-19.496-3.875-7.7-12.259-16.884-30.183-16.884z" />
    </svg>
  );
}

export default SvgInfinity;
