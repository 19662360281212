import * as React from "react";
import PropTypes from 'prop-types';

function SvgProjects(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M19.333 8.118L12 12.485 4.667 8.118 12 3.75l7.333 4.368z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.667 12L12 16.368 19.333 12"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.667 15.882L12 20.25l7.333-4.368"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgProjects.propTypes = { currentColor: PropTypes.string };SvgProjects.defaultProps = { stroke: '#212121' };export default SvgProjects;
