import * as React from "react";
import PropTypes from 'prop-types';

function SvgEditPen(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M1.852 11.318l9.465-9.466a.916.916 0 011.295 0l1.537 1.536a.916.916 0 010 1.296l-9.467 9.464a.912.912 0 01-.647.269H1.583v-2.452c0-.243.096-.476.269-.647z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgEditPen.propTypes = { currentColor: PropTypes.string };SvgEditPen.defaultProps = { stroke: '#9E9E9E' };export default SvgEditPen;
