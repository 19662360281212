import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const Minus = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M24.0001 4.00024C12.9601 4.00024 4.00006 12.9602 4.00006 24.0002C4.00006 35.0402 12.9601 44.0002 24.0001 44.0002C35.0401 44.0002 44.0001 35.0402 44.0001 24.0002C44.0001 12.9602 35.0401 4.00024 24.0001 4.00024ZM34.0001 26.0002H14.0001V22.0002H34.0001V26.0002Z"
						fill={props.currentColor}
		/>
	</svg>
);

Minus.propTypes = {
	currentColor: PropTypes.string
};

Minus.defaultProps = {
	fill: colors.black
};

export default Minus;
