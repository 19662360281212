import * as React from "react";
import PropTypes from 'prop-types';

function SvgKey(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M20.25 12h-9.047M20.25 15.19V12M16.583 15.19V12M10.111 9.365a3.726 3.726 0 11-5.27 5.27 3.726 3.726 0 015.27-5.27"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgKey.propTypes = { currentColor: PropTypes.string };SvgKey.defaultProps = { stroke: '#212121' };export default SvgKey;
