import * as React from "react";
import PropTypes from 'prop-types';

function SvgAttention(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.68 3.118c.84.013 4.236 1.886 4.87 6.334.69-.658 1.074-2.712.874-3.584C17.438 8.065 19 10.887 19 14.035c0 3.77-2.616 6.848-6.835 6.848-4.369 0-6.892-2.778-6.892-6.126 0-4.634 3.852-5.64 3.406-11.64zm2.487 14.614c-.571.627-.28 1.44.582 1.513.79.069 1.341-.6 1.55-1.326.208-.724.068-1.495-.282-2.142-.76 1.122-1.159 1.214-1.85 1.955zm3.37 1.19c1.9-.849 2.938-2.727 2.938-4.87 0-1.746-.716-3.685-1.906-5.212-.567 2.122-2.23 2.945-3.433 3.16.039-2.383-.242-4.554-1.984-6.246-.226 3.503-3.295 5.648-3.354 8.967-.022 1.118.392 2.37 1.32 3.268.374.362.803.645 1.268.859a2.247 2.247 0 01.149-1.368c.695-1.56 2.542-1.718 3.205-4.26.726.555 2.497 2.594 2.118 4.707-.062.347-.17.683-.32.995z"
        fill={props.currentColor}
      />
    </svg>
  );
}

SvgAttention.propTypes = { currentColor: PropTypes.string };SvgAttention.defaultProps = { fill: '#212121' };export default SvgAttention;
