import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgEyeOff = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M35.62 26.7802C39.1408 23.8882 41.4232 19.7598 42 15.2402C42.0315 14.9775 42.011 14.7113 41.9396 14.4566C41.8682 14.2018 41.7474 13.9637 41.5839 13.7557C41.4205 13.5477 41.2177 13.3739 40.9871 13.2443C40.7565 13.1146 40.5026 13.0317 40.24 13.0002C39.9774 12.9687 39.7111 12.9892 39.4564 13.0606C39.2017 13.132 38.9635 13.2528 38.7555 13.4163C38.5475 13.5797 38.3737 13.7825 38.2441 14.0131C38.1145 14.2437 38.0315 14.4975 38 14.7602C37.5219 18.1328 35.8423 21.2194 33.2702 23.4525C30.6981 25.6857 27.4063 26.9153 24 26.9153C20.5937 26.9153 17.3019 25.6857 14.7298 23.4525C12.1577 21.2194 10.4781 18.1328 10 14.7602C9.96849 14.4975 9.88555 14.2437 9.75592 14.0131C9.62629 13.7825 9.45252 13.5797 9.24451 13.4163C9.03651 13.2528 8.79835 13.132 8.54364 13.0606C8.28893 12.9892 8.02265 12.9687 7.76001 13.0002C7.49736 13.0317 7.2435 13.1146 7.01291 13.2443C6.78232 13.3739 6.57952 13.5477 6.41609 13.7557C6.25266 13.9637 6.13179 14.2018 6.0604 14.4566C5.98901 14.7113 5.96849 14.9775 6.00001 15.2402C6.57168 19.7569 8.84665 23.8847 12.36 26.7802L7.76001 31.4202C7.43235 31.8028 7.26114 32.2949 7.28058 32.7983C7.30002 33.3016 7.50869 33.7791 7.86488 34.1353C8.22107 34.4915 8.69855 34.7002 9.2019 34.7196C9.70525 34.7391 10.1974 34.5678 10.58 34.2402L15.8 29.0402C17.7396 30.0204 19.8397 30.6436 22 30.8802V38.0002C22 38.5306 22.2107 39.0393 22.5858 39.4144C22.9609 39.7895 23.4696 40.0002 24 40.0002C24.5304 40.0002 25.0391 39.7895 25.4142 39.4144C25.7893 39.0393 26 38.5306 26 38.0002V30.8802C28.1603 30.6436 30.2604 30.0204 32.2 29.0402L37.42 34.2402C37.8026 34.5678 38.2948 34.7391 38.7981 34.7196C39.3015 34.7002 39.7789 34.4915 40.1351 34.1353C40.4913 33.7791 40.7 33.3016 40.7194 32.7983C40.7389 32.2949 40.5677 31.8028 40.24 31.4202L35.62 26.7802Z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgEyeOff.propTypes = {
	currentColor: PropTypes.string
};

SvgEyeOff.defaultProps = {
	fill: colors.black
};

export default SvgEyeOff;
