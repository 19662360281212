import * as React from "react";
import PropTypes from 'prop-types';

function SvgLightBulb(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M9.25 20.25h5.5"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M14.749 16.73v.976h-5.5v-.977a3.24 3.24 0 00-1.196-2.491A6.163 6.163 0 015.82 9.79c-.17-3.43 2.649-6.444 6.082-6.497a6.186 6.186 0 016.285 6.185 6.16 6.16 0 01-2.265 4.778 3.196 3.196 0 00-1.173 2.472z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.828 15.162h6.335"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgLightBulb.propTypes = { currentColor: PropTypes.string };SvgLightBulb.defaultProps = { stroke: '#212121' };export default SvgLightBulb;
