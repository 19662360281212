import * as React from "react";
import PropTypes from 'prop-types';

function SvgAddExisting(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M13.52 19.625H5.105A2.104 2.104 0 013 17.521V9.104C3 7.942 3.942 7 5.104 7h8.417c1.162 0 2.104.942 2.104 2.104v8.417a2.104 2.104 0 01-2.104 2.104z"
        stroke={props.currentColor}
        strokeWidth={1.148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.901 10.165a1.227 1.227 0 11-1.736 1.736 1.227 1.227 0 011.736-1.736M15.625 11.735a6.827 6.827 0 00-.964-.077c-3.729 0-6.751 3.274-6.751 7.003 0 .328.031.649.076.964"
        stroke={props.currentColor}
        strokeWidth={1.148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 4.292h4.583M19.292 6.583V2"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgAddExisting.propTypes = { currentColor: PropTypes.string };SvgAddExisting.defaultProps = { stroke: '#323232' };export default SvgAddExisting;
