import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { radius, colors } from '../guidelines/shared';

const StyledButton = styled.button`
      cursor: pointer;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: bold;
      line-height: 1;
      outline: 0;
      border: none;
      font-size: 14px;
      min-height: 40px;
    

    ${props =>
      props.appearance === 'primary' &&
      ` 
      background: ${colors.primary}; 
      border-radius: ${props.radius};
      color: #fff;
      padding: 0 2rem;
      width: ${props.width}%;
      transition: all .4s;

      &:hover {
        transition: all .4s;
        box-shadow: 0px 20px 30px rgba(62, 33, 222, 0.2);
      }
      `}

    ${props =>
      props.appearance === 'secondary' &&
      ` 
      background: #fff; 
      padding: 0 2rem;
      color: ${colors.primary};
      border-radius: ${props.radius};
      border: 2px solid ${colors.primary};
      width: ${props.width}%;
      
      &:hover {
        box-shadow: none;
      }
      `}
    ${props =>
      props.appearance === 'tertiary' &&
      ` 
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
      background: #fff; 
      padding: 0 1.6rem;
      color: ${colors.primary};
      border-radius: ${props.radius};
      width: ${props.width}%;
      
      &:hover {
        box-shadow: 0px 20px 30px rgba(62, 33, 222, 0.2);
      }
      `}
 
      ${props =>
        props.disabled &&
        props.appearance === 'primary' &&
        ` background: #E2E0EC; color: #fff;
        &:hover {
          box-shadow: none;  
      } `}
      ${props =>
        props.disabled &&
        props.appearance === 'secondary' &&
        `
       background: transparent;
       border: 2px solid #E2E0EC; 
       color: #E2E0EC;
      
      &:hover {
          box-shadow: none;  
      } 
      `}
      ${props =>
        props.disabled &&
        props.appearance === 'tertiary' &&
        ` 
      background: #E2E0EC;
      color: #fff; 
      box-shadow: none;
       &:hover {
          box-shadow: none;  
      } 
      `}
`;

const StyledBtn = props => {
  return (
    <div>
      <StyledButton mr={2} {...props}>
        {props.children}
      </StyledButton>
    </div>
  );
};

StyledBtn.propTypes = {
  appearance: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  disabled: PropTypes.bool,
  radius: PropTypes.string,
  width: PropTypes.string
};

StyledBtn.defaultProps = {
  appearance: 'primary',
  disabled: false,
  radius: radius.soft,
  width: 'auto'
};

export default StyledBtn;
