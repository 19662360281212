import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgCreate = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M6 34.5V42h7.5l22.13-22.13-7.5-7.5L6 34.5zm35.41-20.41c.78-.78.78-2.05 0-2.83l-4.67-4.67c-.78-.78-2.05-.78-2.83 0l-3.66 3.66 7.5 7.5 3.66-3.66z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgCreate.propTypes = {
	currentColor: PropTypes.string
};

SvgCreate.defaultProps = {
	fill: colors.black
};
export default SvgCreate;
