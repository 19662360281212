import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgSearch = props => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
		<path
			d="M16.667 14.667h-1.06l-.367-.367a8.584 8.584 0 002.093-5.633A8.666 8.666 0 008.667 0 8.666 8.666 0 000 8.667a8.666 8.666 0 008.667 8.666c2.153 0 4.12-.786 5.633-2.086l.367.366v1.054l6.666 6.653 1.987-1.987-6.653-6.666zm-8 0a6 6 0 110-12.001 6 6 0 010 12z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgSearch.propTypes = {
	currentColor: PropTypes.string
};

SvgSearch.defaultProps = {
	fill: colors.black
};

export default SvgSearch;
