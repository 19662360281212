import React from "react";
import PropTypes from "prop-types";
import { colors } from "../guidelines/shared";

const SvgLogo = props => (
	<svg width={48} height={48} fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M33.1013 38.3911C39.6799 31.602 41.3883 23.7573 38.2265 14.8571C34.6822 6.81067 26.8058 5.26669 14.5973 10.2252C2.38886 15.1837 0.776103 22.8149 9.75905 33.1189C18.742 43.4229 26.5228 45.1803 33.1013 38.3911ZM28.661 31.5801C33.2364 26.855 34.4211 21.392 32.2153 15.1911C29.7546 9.60067 24.2739 8.52487 15.7732 11.9637C7.27252 15.4025 6.15147 20.7129 12.4101 27.8948C18.6686 35.0767 24.0856 36.3051 28.661 31.5801Z"
			fill={props.currentColor}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M24.8705 25.7361C27.637 22.8679 28.3418 19.5406 26.9847 15.7541C25.4855 12.3553 22.1558 11.6885 16.9955 13.7536C11.8353 15.8188 11.1661 19.0492 14.9879 23.4448C18.8098 27.8405 22.104 28.6043 24.8705 25.7361ZM21.9938 21.283C23.275 19.9407 23.5866 18.3694 22.9287 16.5692C22.2064 14.9517 20.6411 14.6172 18.2328 15.5658C15.8245 16.5143 15.529 18.0399 17.3463 20.1425C19.1635 22.2451 20.7127 22.6252 21.9938 21.283Z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgLogo.propTypes = {
	currentColor: PropTypes.string
};

SvgLogo.defaultProps = {
	fill: colors.purple
};

export default SvgLogo;
