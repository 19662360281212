import * as React from "react";
import PropTypes from 'prop-types';

function SvgHiddenEye(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M11 17.417a6.364 6.364 0 01-2.295-.454M19.142 11.429c-1.735 3.207-4.938 5.988-8.142 5.988M17.49 8.178c.633.741 1.196 1.55 1.652 2.394a.905.905 0 010 .857M4.583 17.417L17.418 4.583M8.958 13.041a2.888 2.888 0 014.084-4.083"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.623 6.376C14.205 5.28 12.603 4.583 11 4.583c-3.204 0-6.408 2.78-8.142 5.989a.905.905 0 000 .857c.867 1.603 2.1 3.1 3.518 4.196"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgHiddenEye.propTypes = { currentColor: PropTypes.string };SvgHiddenEye.defaultProps = { stroke: '#fff' };export default SvgHiddenEye;
