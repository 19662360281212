import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgReport = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M31.46 6H16.54L6 16.54v14.91L16.54 42h14.91L42 31.46V16.54L31.46 6zM24 34.6c-1.43 0-2.6-1.16-2.6-2.6 0-1.43 1.17-2.6 2.6-2.6a2.601 2.601 0 010 5.2zm2-8.6h-4V14h4v12z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgReport.propTypes = {
	currentColor: PropTypes.string
};

SvgReport.defaultProps = {
	fill: colors.black
};

export default SvgReport;
