import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgBookmarkOutline = props => (
	<svg width="1em" height="1em" viewBox="0 0 28 36" fill="none" {...props}>
		<path
			d="M24 0H4C1.79 0 .02 1.79.02 4L0 36l14-6 14 6V4c0-2.21-1.79-4-4-4zm0 30l-10-4.35L4 30V4h20v26z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgBookmarkOutline.propTypes = {
	currentColor: PropTypes.string
};

SvgBookmarkOutline.defaultProps = {
	fill: colors.black
};

export default SvgBookmarkOutline;
