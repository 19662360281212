import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgFullscreenExit = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M10 32h6v6h4V28H10v4zm6-16h-6v4h10V10h-4v6zm12 22h4v-6h6v-4H28v10zm4-22v-6h-4v10h10v-4h-6z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgFullscreenExit.propTypes = {
	currentColor: PropTypes.string
};

SvgFullscreenExit.defaultProps = {
	fill: colors.black
};

export default SvgFullscreenExit;
