import * as React from "react";
import PropTypes from 'prop-types';

function SvgCircleMinus(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M15.667 12H8.333"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M12 20.25v0A8.25 8.25 0 013.75 12v0A8.25 8.25 0 0112 3.75v0A8.25 8.25 0 0120.25 12v0A8.25 8.25 0 0112 20.25z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgCircleMinus.propTypes = { currentColor: PropTypes.string };SvgCircleMinus.defaultProps = { stroke: '#3E21DE' };export default SvgCircleMinus;
