import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const BlueCheckbox = withStyles({
  root: {
    color: '#3E21DE',
    '&$checked': {
      color: '#3E21DE'
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const Checkboxes = ({ msg, handleCheckboxChange, checked }) => {
  // const [checked, setChecked] = React.useState(true);

  // const handleChange = event => {
  //   setChecked(event.target.checked);
  //   console.log(event.target.checked);
  // };
  // console.log(msg);
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <BlueCheckbox
             checked={checked}
            onChange={handleCheckboxChange}
            value="secondary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        }
        label={msg}
      />
    </FormGroup>
  );
};

export default Checkboxes;
