import * as React from "react";
import PropTypes from 'prop-types';

function SvgChevronLeft(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M13.833 8.333L10.167 12l3.666 3.667"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgChevronLeft.propTypes = { currentColor: PropTypes.string };SvgChevronLeft.defaultProps = { stroke: '#212121' };export default SvgChevronLeft;
