import * as React from "react";
import PropTypes from 'prop-types';

function SvgReadability(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M12.292 11.342a11.118 11.118 0 012.292-.489M5.417 10.854c.805.09 1.558.245 2.292.489M14.584 7.798c-.806.09-1.558.246-2.292.49M7.709 8.287a11.122 11.122 0 00-2.292-.49"
        stroke={props.currentColor}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M10 5.516C8.02 4.406 6.249 3.965 3.903 3.89a.76.76 0 00-.777.764v9.055c0 .413.331.756.745.762 2.361.035 4.14.47 6.13 1.64 1.991-1.17 3.77-1.605 6.131-1.64a.761.761 0 00.744-.763V4.652a.76.76 0 00-.776-.763c-2.346.076-4.117.517-6.098 1.627z"
        stroke={props.currentColor}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 5.516V16.11"
        stroke={props.currentColor}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgReadability.propTypes = { currentColor: PropTypes.string };SvgReadability.defaultProps = { stroke: '#3E21DE' };export default SvgReadability;
