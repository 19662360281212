import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgClose = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M38 12.83L35.17 10 24 21.17 12.83 10 10 12.83 21.17 24 10 35.17 12.83 38 24 26.83 35.17 38 38 35.17 26.83 24 38 12.83z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgClose.propTypes = {
	currentColor: PropTypes.string
};

SvgClose.defaultProps = {
	fill: colors.black
};

export default SvgClose;
