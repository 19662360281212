import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgFileUpload = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path d="M18 32h12V20h8L24 6 10 20h8v12zm-8 4h28v4H10v-4z" fill={props.currentColor} />
	</svg>
);

SvgFileUpload.propTypes = {
	currentColor: PropTypes.string
};

SvgFileUpload.defaultProps = {
	fill: colors.black
};

export default SvgFileUpload;
