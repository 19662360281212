import * as React from "react";
import PropTypes from 'prop-types';

function SvgUpload(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 3.75v12.834M19.333 20.25H4.667M7.416 8.334L12 3.75l4.583 4.584"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgUpload.propTypes = { currentColor: PropTypes.string };SvgUpload.defaultProps = { stroke: '#212121' };export default SvgUpload;
