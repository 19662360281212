import * as React from "react";
import PropTypes from 'prop-types';

function SvgDoubleRight(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M12.375 14.667l3.667-3.666-3.667-3.667M5.958 15.584L10.541 11 5.958 6.417"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgDoubleRight.propTypes = { currentColor: PropTypes.string };SvgDoubleRight.defaultProps = { stroke: '#3E21DE' };export default SvgDoubleRight;
