import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgInfo = props => (
	<svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
		<path
			d="M18 30h4V18h-4v12zm2-30C8.95 0 0 8.95 0 20s8.95 20 20 20 20-8.95 20-20S31.05 0 20 0zm0 36c-8.82 0-16-7.18-16-16S11.18 4 20 4s16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgInfo.propTypes = {
	currentColor: PropTypes.string
};

SvgInfo.defaultProps = {
	fill: colors.black
};

export default SvgInfo;
