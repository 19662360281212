import * as React from "react";
import PropTypes from 'prop-types';

function SvgThunderCredits(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M18.254 11.61l-5.51 7.96c-.505.729-1.648.372-1.648-.515v-5.246H6.49a.904.904 0 01-.744-1.42l5.51-7.96c.506-.728 1.649-.372 1.649.515v5.247h4.606c.729 0 1.159.819.743 1.42z"
        stroke={props.currentColor}
        strokeWidth={1.538}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgThunderCredits.propTypes = { currentColor: PropTypes.string };SvgThunderCredits.defaultProps = { stroke: '#212121' };export default SvgThunderCredits;
