import * as React from "react";
import PropTypes from 'prop-types';

function SvgTarget(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8 5.6a2.4 2.4 0 100 4.8 2.4 2.4 0 000-4.8zm0 3.2a.8.8 0 110-1.6.8.8 0 010 1.6zm7.2-1.6h-.856A6.4 6.4 0 008.8 1.656V.8a.8.8 0 00-1.6 0v.856A6.4 6.4 0 001.656 7.2H.8a.8.8 0 000 1.6h.856A6.4 6.4 0 007.2 14.344v.856a.8.8 0 101.6 0v-.856A6.4 6.4 0 0014.344 8.8h.856a.8.8 0 100-1.6zM8 12.8a4.8 4.8 0 110-9.6 4.8 4.8 0 010 9.6z"
        fill={props.currentColor}
      />
    </svg>
  );
}

SvgTarget.propTypes = { currentColor: PropTypes.string };SvgTarget.defaultProps = { fill: '#3E21DE' };export default SvgTarget;
