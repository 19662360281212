import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { radius, colors, hoverCheck } from '../guidelines/shared';
import Checkbox from '@material-ui/core/Checkbox';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#fff',
    color: colors.primary,
    textAlign: 'left',
    fontWeight: 900,
    fontSize: 16
  },
  body: {
    fontSize: 14,
    textAlign: 'left'
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff'
    }
  }
}))(TableRow);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },

  table: {
    minWidth: 700,
    borderRadius: '10px',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
    border: 'none'
  }
}));

const StyledCheckbox = withStyles({
  root: {
    color: '#C3BAF5',
    '&$checked': {
      color: colors.primary,
      '&:hover, &:focus, &:active': {
        backgroundColor: hoverCheck.hover
      }
    },
    '&:hover, &:focus, &:active': {
      backgroundColor: hoverCheck.hover
    },
    '& span > svg': {
      width: '27px',
      height: '27px'
    }
  },
  checked: {}
})(props => <Checkbox {...props} />);

const CustomizedTables = props => {
  const classes = useStyles();
  const { rows, titles } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell padding="checkbox">
              <span></span>
            </StyledTableCell>
            {titles.map((title, i) => {
              return (
                <StyledTableCell align="right" key={i}>
                  {title}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <StyledTableRow key={row.name}>
              <TableCell padding="checkbox" component="th" scope="row">
                <StyledCheckbox inputProps={{ 'aria-label': 'select all desserts' }} />
              </TableCell>
              <StyledTableCell align="right">{row.name}</StyledTableCell>
              <StyledTableCell align="right">{row.calories}</StyledTableCell>
              <StyledTableCell align="right">{row.fat}</StyledTableCell>
              <StyledTableCell align="right">{row.carbs}</StyledTableCell>
              <StyledTableCell align="right">{row.protein}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default CustomizedTables;
