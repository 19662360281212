import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgLogout = props => (
	<svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
		<path
			d="M14.17 25.17L17 28L27 18L17 8L14.17 10.83L19.34 16H0V20H19.34L14.17 25.17ZM32 0H4C1.79 0 0 1.79 0 4V12H4V4H32V32H4V24H0V32C0 34.21 1.79 36 4 36H32C34.21 36 36 34.21 36 32V4C36 1.79 34.21 0 32 0Z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgLogout.propTypes = {
	currentColor: PropTypes.string
};

SvgLogout.defaultProps = {
	fill: colors.black
};

export default SvgLogout;
