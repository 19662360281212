import * as React from "react";
import PropTypes from 'prop-types';

function SvgCheck(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M19.333 6.958L9.25 17.042l-4.583-4.584"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgCheck.propTypes = { currentColor: PropTypes.string };SvgCheck.defaultProps = { stroke: '#212121' };export default SvgCheck;
