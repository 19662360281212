import * as React from "react";
import PropTypes from 'prop-types';

function SvgSuccess(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M12 20.25v0A8.25 8.25 0 013.75 12v0A8.25 8.25 0 0112 3.75v0A8.25 8.25 0 0120.25 12v0A8.25 8.25 0 0112 20.25z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.667 10.167l-4.584 4.583L8.333 12"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgSuccess.propTypes = { currentColor: PropTypes.string };SvgSuccess.defaultProps = { stroke: '#212121' };export default SvgSuccess;
