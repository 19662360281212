import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgRefresh = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M35.3 12.7C32.41 9.8 28.42 8 24 8 15.16 8 8.02 15.16 8.02 24S15.16 40 24 40c7.45 0 13.69-5.1 15.46-12H35.3c-1.65 4.66-6.07 8-11.3 8-6.63 0-12-5.37-12-12s5.37-12 12-12c3.31 0 6.28 1.38 8.45 3.55L26 22h14V8l-4.7 4.7z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgRefresh.propTypes = {
	currentColor: PropTypes.string
};

SvgRefresh.defaultProps = {
	fill: colors.black
};

export default SvgRefresh;
