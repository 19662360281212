import * as React from "react";
import PropTypes from 'prop-types';

function SvgFullPage(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 26" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M18.204 18.333H8.795A1.232 1.232 0 017.563 17.1v-6.023c0-.68.551-1.232 1.232-1.232h9.408c.681 0 1.233.551 1.233 1.232v6.024c0 .68-.552 1.232-1.232 1.232z"
        stroke={props.currentColor}
        strokeWidth={1.219}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.23 16.698H9.77c-.54 0-.977-.35-.977-.781v-4.599h9.414v4.599c0 .431-.438.78-.977.78zM17.23 8.63H8.793V4.03c0-.431.437-.781.977-.781h7.46c.54 0 .977.35.977.781V8.63h-.977z"
        fill={props.currentColor}
      />
      <path
        d="M9.178 20.901c2.388-.821 6.26-.821 8.648 0"
        stroke={props.currentColor}
        strokeWidth={1.219}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgFullPage.propTypes = { currentColor: PropTypes.string, currentColor: PropTypes.string };SvgFullPage.defaultProps = { stroke: '#1D0F68', fill: '#1D0F68' };export default SvgFullPage;
