import * as React from "react";
import PropTypes from 'prop-types';

function SvgSearch(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M15.404 7.268a5.753 5.753 0 11-8.136 8.136 5.753 5.753 0 018.136-8.136M18.417 18.417L15.4 15.4"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgSearch.propTypes = { currentColor: PropTypes.string };SvgSearch.defaultProps = { stroke: '#212121' };export default SvgSearch;
