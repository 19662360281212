import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgLightBall = props => (
	<svg idth="1em" height="1em" viewBox="0 0 14 21" fill="none" {...props}>
		<path
			d="M4 19.9268C4 20.4768 4.45 20.9268 5 20.9268H9C9.55 20.9268 10 20.4768 10 19.9268V18.9268H4V19.9268ZM7 0.926849C3.135 0.926849 0 4.06185 0 7.92685C0 10.3068 1.19 12.4018 3 13.6668V15.9268C3 16.4768 3.45 16.9268 4 16.9268H10C10.55 16.9268 11 16.4768 11 15.9268V13.6668C12.81 12.4018 14 10.3068 14 7.92685C14 4.06185 10.865 0.926849 7 0.926849ZM9.855 12.0268L9 12.6218V14.9268H5V12.6268L4.145 12.0318C2.8 11.0918 2 9.56185 2 7.93185C2 5.17685 4.245 2.93185 7 2.93185C9.755 2.93185 12 5.17685 12 7.93185C12 9.55685 11.2 11.0869 9.855 12.0268Z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgLightBall.propTypes = {
	currentColor: PropTypes.string
};

SvgLightBall.defaultProps = {
	fill: colors.black
};

export default SvgLightBall;
