import React from "react";
import PropTypes from "prop-types";
import { colors } from "../../shared";

const SvgCardMenuHorizontal = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 8" fill="none" {...props}>
		<path
			d="M4 0C1.79 0 0 1.79 0 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm24 0c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zM16 0c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgCardMenuHorizontal.propTypes = {
	currentColor: PropTypes.string
};

SvgCardMenuHorizontal.defaultProps = {
	fill: colors.black
};

export default SvgCardMenuHorizontal;
