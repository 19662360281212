import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const Flash = props => (
	<svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
		<path
			d="M22.22 46.0002C21.9881 45.9999 21.758 45.9593 21.54 45.8802C21.1195 45.7262 20.7628 45.4356 20.5271 45.0548C20.2914 44.6741 20.1903 44.2252 20.24 43.7802L21.78 29.6002H10C9.63666 29.6007 9.28003 29.5023 8.96842 29.3154C8.65682 29.1286 8.402 28.8604 8.23133 28.5396C8.06067 28.2188 7.98061 27.8577 7.99976 27.4948C8.0189 27.132 8.13653 26.7812 8.34 26.4802L24.12 2.88019C24.3696 2.51087 24.7354 2.2355 25.1593 2.09781C25.5833 1.96012 26.041 1.968 26.46 2.12019C26.8628 2.26972 27.2067 2.54496 27.4408 2.90517C27.6749 3.26539 27.7869 3.69142 27.76 4.12019L26.22 18.4002H38C38.3633 18.3996 38.72 18.4981 39.0316 18.6849C39.3432 18.8718 39.598 19.14 39.7687 19.4608C39.9393 19.7815 40.0194 20.1427 40.0002 20.5056C39.9811 20.8684 39.8635 21.2192 39.66 21.5202L23.88 45.1202C23.6965 45.3916 23.4492 45.6138 23.1598 45.7673C22.8703 45.9207 22.5476 46.0007 22.22 46.0002ZM13.74 25.6002H24C24.2791 25.6009 24.5549 25.6599 24.8098 25.7736C25.0647 25.8873 25.293 26.053 25.48 26.2602C25.6687 26.4702 25.8109 26.7178 25.897 26.9867C25.9832 27.2555 26.0115 27.5396 25.98 27.8202L25.08 36.1202L34.26 22.4002H24C23.7178 22.4019 23.4384 22.3439 23.1802 22.2299C22.922 22.1159 22.6908 21.9486 22.5019 21.7389C22.313 21.5292 22.1706 21.2819 22.0841 21.0133C21.9976 20.7446 21.9689 20.4607 22 20.1802L22.9 11.8802L13.74 25.6002Z"
			fill={props.currentColor}
		/>
	</svg>
);

Flash.propTypes = {
	currentColor: PropTypes.string
};

Flash.defaultProps = {
	fill: colors.black
};

export default Flash;
