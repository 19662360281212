import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared';

const SvgTerms = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 40" fill="none" {...props}>
		<path
			d="M20 0H4C1.79 0 0.0200005 1.79 0.0200005 4L0 36C0 38.21 1.77 40 3.98 40H28C30.21 40 32 38.21 32 36V12L20 0ZM24 32H8V28H24V32ZM24 24H8V20H24V24ZM18 14V3L29 14H18Z"
			fill={props.currentColor}
		/>
	</svg>
);

SvgTerms.propTypes = {
	currentColor: PropTypes.string
};

SvgTerms.defaultProps = {
	fill: colors.black
};

export default SvgTerms;
