import * as React from "react";
import PropTypes from 'prop-types';

function SvgVisibleEye(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M2.874 13.748a.9.9 0 010-.913C4.62 9.881 7.81 7.333 11 7.333c3.19 0 6.38 2.548 8.125 5.501a.905.905 0 010 .916c-1.746 2.953-4.935 5.5-8.125 5.5-3.19 0-6.38-2.547-8.126-5.502z"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.945 11.347a2.75 2.75 0 11-3.888 3.89 2.75 2.75 0 013.888-3.89"
        stroke={props.currentColor}
        strokeWidth={1.429}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 2.292v2.291M4.583 4.125l1.54 1.833M17.417 4.125l-1.54 1.833"
        stroke={props.currentColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgVisibleEye.propTypes = { currentColor: PropTypes.string };SvgVisibleEye.defaultProps = { stroke: '#fff' };export default SvgVisibleEye;
