import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Body, SmallBody } from '../guidelines/typography';
import { radius, colors } from '../guidelines/shared';
import Dropdown from './Dropdown';

const StyledTitle = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;
	position: relative;
`;

const CardTiTle = props => {
	const { menuItems } = props;

	return (
		<StyledTitle>
			<Body type="lt" color={colors.purple}>
				{props.title}
			</Body>
			{menuItems && <Dropdown items={menuItems} />}
		</StyledTitle>
	);
};

export default CardTiTle;
