import * as React from "react";
import PropTypes from 'prop-types';

function SvgAboveTheFold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M18.316 18.14H8.497c-.71 0-1.286-.576-1.286-1.286v-6.285c0-.711.576-1.287 1.286-1.287h9.818c.71 0 1.287.576 1.287 1.287v6.285c0 .71-.576 1.286-1.286 1.286z"
        stroke={props.currentColor}
        strokeWidth={0.965}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.234 16.434H9.45c-.564 0-1.02-.365-1.02-.815v-3.983c0-.45.456-.816 1.02-.816h7.784c.563 0 1.02.365 1.02.816v3.983c0 .45-.457.815-1.02.815z"
        fill={props.currentColor}
      />
      <path
        d="M8.895 20.82c2.491-.858 6.532-.858 9.025 0"
        stroke={props.currentColor}
        strokeWidth={0.965}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgAboveTheFold.propTypes = { currentColor: PropTypes.string, currentColor: PropTypes.string };SvgAboveTheFold.defaultProps = { stroke: '#1D0F68', fill: '#1D0F68' };export default SvgAboveTheFold;
